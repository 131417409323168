import { defaultTimeZone, TIME_ZONE, URL_HOME } from "./constants";

const setStateStorage = (data: any, key: string) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    localStorage.clear();
    localStorage.setItem(key, JSON.stringify(data));
    setTimeZoneStorage(defaultTimeZone);
  }
};

const getStateStorage = (key: string) => {
  try {
    const data = localStorage.getItem(key);
    if (data) {
      const dataParsed = JSON.parse(data);
      return dataParsed;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const setStateSession = (data: any, key: string) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    sessionStorage.clear();
    sessionStorage.setItem(key, JSON.stringify(data));
    setTimeZoneStorage(defaultTimeZone);
  }
};

const getStateSession = (key: string) => {
  try {
    const data = sessionStorage.getItem(key);
    if (data) {
      const dataParsed = JSON.parse(data);
      return dataParsed;
    }
    return null;
  } catch (error) {
    return null;
  }
};
export const setTimeZoneStorage = (timeZone: string) =>
  setStateStorage(timeZone, `${TIME_ZONE}`);
export const getTimeZoneStorage = () => getStateStorage(`${TIME_ZONE}`);

export const loadUrlHome = () => getStateSession(URL_HOME);

export const saveUrlHome = (url: any) => {
  setStateSession(url, URL_HOME);
  setStateStorage(url, URL_HOME);
};
