import { del, get, set } from 'idb-keyval';
import { urlHome } from 'submodules/OrderCore';
import { URL_HOME } from 'submodules/OrderCore/src/constants';
import { ONLINE, ORDER_SUCCESS } from 'types/globalType';
import { TYPES } from 'utils/constants';
import { StateCreator, create } from 'zustand';
import { PersistOptions, StateStorage, createJSONStorage, persist } from 'zustand/middleware';

export const IDBStorage: StateStorage = {
  getItem: async (name) => {
    if (typeof indexedDB === 'undefined') {
      return null;
    }

    const value = await get(name);

    return value || null;
  },
  setItem: async (name, value) => {
    if (typeof indexedDB === 'undefined') {
      return;
    }
    set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    if (typeof indexedDB === 'undefined') {
      return;
    }
    await del(name);
  },
};

export const dataPersist = (state) => {
  const temp = { ...state };
  let result = {};
  Object.keys(temp).map((i) => {
    if (i !== 'categories') {
      result[i] = temp[i];
    } else {
      result[i] = [];
    }
  });
  return result;
};

export type MyPersist<T> = (config: StateCreator<T>, options: PersistOptions<T>) => StateCreator<T>;

export const renderName = (name: string) => {
  const pathName = window?.location?.pathname.replace(/ /g, '%20') || '';
  const keys = pathName.split('/');
  if (keys.length < 2) return name;
  const type = keys[1];

  const urlHomeLocal = localStorage.getItem(URL_HOME);
  const urlLocals = (urlHomeLocal ?? '').split('/');
  const urlType = urlLocals.length > 1 ? urlLocals[1] : ONLINE;

  if (keys.length <= 3) {
    if (urlHomeLocal) return `${name}-${urlHome(JSON.parse(urlHomeLocal))}`;
    return name;
  }
  if (keys[1] !== ORDER_SUCCESS) {
    if (TYPES.includes(type)) return `${name}-${urlHome(pathName)}`;
  }
  return `${name}-${urlHome(pathName.replace(ORDER_SUCCESS, urlType))}`;
};

export const createStoreCustom = <T>(initialState, customAction, storeName, customState) =>
  create<T>(
    (persist as MyPersist<T>)(
      (set, get) => ({
        [customState]: initialState,
        ...customAction(get, set),
      }),
      {
        name: renderName(storeName), // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      },
    ),
  );
