/* eslint-disable indent */
import { ZH_CN, ZH_HK, ZH_SG, ZH_TW } from "../constants";
import { ELANGUAGE, IAds } from "../types";
export const getLanguage = () => {
  return window.navigator.language;
};

export const checkChineseLanguage = () => {
  const language = getLanguage() || "en-US";
  return (
    language.toLowerCase() === ZH_CN.toLowerCase() ||
    language.toLowerCase() === ZH_SG.toLowerCase() ||
    language.toLowerCase() === ZH_TW.toLowerCase() ||
    language.toLowerCase() === ZH_HK.toLowerCase()
  );
};

export const checkEmptyBanner = (array: IAds[]) => {
  const temp = array.every((item: IAds) => {
    return item.resources.length === 0;
  });
  return temp;
};

export const checkAdsLanguage = (ads: any) => {
  const language = checkChineseLanguage()
    ? ELANGUAGE.CHINESE
    : ELANGUAGE.ENGLISH;
  let tempAds = [...ads];
  ads.forEach((itemAds: any, index: any) => {
    const temp = itemAds.resources.filter((resource: any) => {
      return resource.language === language;
    });
    tempAds.splice(index, 1, { ...tempAds[index], resources: temp });
  });
  if (language === ELANGUAGE.CHINESE && checkEmptyBanner(tempAds)) {
    let tempAdsEnglish = [...ads];
    ads.forEach((itemAds: any, index: number) => {
      const temp = itemAds.resources.filter((resource: any) => {
        return resource.language === ELANGUAGE.ENGLISH;
      });
      tempAdsEnglish.splice(index, 1, { ...tempAds[index], resources: temp });
    });
    return tempAdsEnglish;
  } else {
    return tempAds;
  }
};

export const firstThreeBanner = (ads: IAds[]) => {
  let array: string[] = [];
  ads.forEach((item) => {
    item.resources.forEach((i) => {
      if (array.length < 3) {
        array = [...array, i.url];
      }
    });
  });
  return array;
};
