import { Box, BoxProps } from '@material-ui/core';
import ReactContentLoader from 'components/ContentLoader';
import React from 'react';

const Loading = (props: BoxProps) => {
  return (
    <Box {...props}>
      <ReactContentLoader></ReactContentLoader>
    </Box>
  );
};

export default Loading;
