import { appConstants } from 'utils/constants';
import API from './api';
import { MenuByIdType } from './interface';
import { getRestaurantId } from 'submodules/OrderCore';

export const getMenusQuery = async (menuId: string, menuVersion: string, params: MenuByIdType) =>
  API.get('/online', {
    params,
    headers: {
      menuId: menuId || '',
      menuVersion: menuVersion || '',
    },
  });

export const getAdsSuccessPageAPI = async () => API.get('/orderSuccessMessages');

export const requestServiceBellAPI = async (
  menuId: string,
  tableId: string,
  serviceType: string,
) => {
  await API.post(
    '/newMessage',
    {
      tableId: tableId.replace(appConstants.required, ''),
      serviceType,
    },
    {
      headers: { restaurantId: menuId },
    },
  );
};

export const getAllGroupRestaurantsAPI = (groupId: string) =>
  API.get(`/groupRestaurants/${groupId}`);

export const placeOrderAPI = (options: any) => {
  return API.post('/placeOrder', options.payload, {
    headers: {
      restaurantId: getRestaurantId(options.infoRestaurant.menuId),
      menuId: options.infoRestaurant.menuId,
    },
  });
};
