import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import { userDarkMode } from 'theme';

export const themeLight = unstable_createMuiStrictModeTheme({
  palette: {
    primary: {
      main: '#f9f9f9',
      contrastText: '#ffffff',
    },

    divider: '#F9F9F9',

    secondary: {
      main: '#989ca5',
    },
    grey: {
      100: '#f9f9f9',
      200: '#f9f9f9',
      300: '#101434',
      400: '#f0f3fa',
      500: '#f9f9f9',
      600: '#ffffff',
      700: '#999999',
      800: '#101434',
      900: '#000000',
    },
    text: {
      primary: '#000000',
      secondary: '#949CB1',
    },
    background: {
      default: '#ffffff',
      paper: '#F8F8FA',
    },
  },
  typography: {
    h2: {
      fontSize: '2.4rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '2.2rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.7rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1.6rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
    fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 370,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
export const themeDark = unstable_createMuiStrictModeTheme({
  palette: {
    primary: {
      main: '#414141',
      contrastText: '#f9f9f9',
    },
    divider: '#313036',
    secondary: {
      main: '#989ca5',
    },
    grey: {
      100: '#101434',
      200: '#1f1e24',
      300: '#ffffff',
      400: '#313036',
      500: '#313036',
      600: '#1f1e24',
      700: '#949cb1',
      800: '#f9f9f9',
      900: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#949CB1',
    },
    background: {
      default: '#1b1b1b',
      paper: '#1f1f23',
    },
  },
  typography: {
    h2: {
      fontSize: '2.4rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '2.2rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.7rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1.6rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
    fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 370,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const themeStep = userDarkMode ? themeDark : themeLight;
