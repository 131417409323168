import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'theme';

const ContainerBox = styled(Box)`
  width: calc(100vw - 40px);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
`;

const TextTitle = styled(Typography)`
  margin-bottom: 30px;
  color: ${theme.palette.primary.main};
  font-size: 20px;
`;

const ButtonTryAgain = styled(Button)`
  font-size: 14px;
`;

interface State {
  isError: boolean;
}
type Props = {};
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { isError: false };
  }

  componentDidCatch() {
    this.setState({
      isError: true,
    });
  }

  handleTryAgain = () => {
    window.location.reload();
  };

  render() {
    const { t } = this.props as any;
    if (this.state.isError)
      return (
        <ContainerBox>
          <TextTitle>
            {t('An error has occurred.')} <br /> {t('Please try again')}
          </TextTitle>
          <ButtonTryAgain variant="contained" onClick={this.handleTryAgain}>
            {t('Try again')}
          </ButtonTryAgain>
        </ContainerBox>
      );
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
