import { MyPersist, dataPersist, renderName } from 'features/home/store/createStore';
import { IServiceBell } from 'features/home/store/homeSlice';
import { ICategory, IRestaurant, TTableId } from 'features/home/types/homeTypes';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { EFetchingStatus } from 'types/globalType';
import {
  EEventAction,
  EEventCategory,
  MESSAGE_SERVICE_BELL_REQUEST_ERROR,
  MESSAGE_SERVICE_BELL_REQUEST_SUCCESS,
} from 'utils/constants';
import { saveUrlHome, setTimeZoneStorage } from 'utils/localStorage';
import {
  IHandleErrorProps,
  handleError,
  handleThrowErrorNull,
  isNull,
  replaceWhiteSpace,
  urlHomeGroup,
} from 'utils/restaurantFeatures';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import produce from 'immer';
import { QueryFunction } from 'react-query';
import { getAllGroupRestaurantsAPI, getMenusQuery, requestServiceBellAPI } from 'api/apiQueries';
import { urlHome } from 'submodules/OrderCore';

interface IOpenHours {
  closeDaysItems: any[];
  openHoursItems: any[];
}

interface IGroupRestaurant {
  address: string;
  altName: string;
  isOnline: boolean;
  logo: string;
  name: string;
  openHours: IOpenHours;
  restaurantId: string;
  restrauntDescription: string;
}

export interface IInfo {
  groupId?: string;
  menuId: string;
  tableId: string;
}
export interface GroupState {
  groupRestaurants: IGroupRestaurant[];
  isLoading: boolean;
  status: EFetchingStatus;
  isDineInTable: boolean;
  categories: ICategory[];
  error?: string;
  inactiveMenu: any[];
  restaurant: IRestaurant;
  tableId: TTableId;
  isOrderingDisabled: boolean;
  info: IInfo;
  menuIdCategories: string;
  menuVersion: string;
  memberId: string;
  urlHome: string;
  categoryTabId: string;
}

const initialState: GroupState = {
  groupRestaurants: [],
  isDineInTable: false,
  categories: [],
  inactiveMenu: [],
  isOrderingDisabled: false,
  status: EFetchingStatus.LOADING,
  isLoading: false,
  tableId: 'takeaway',
  restaurant: {
    orderAheadDisabled: false,
    isDineMobileOptional: false,
    onlineSettings: {},
    holidaySurcharge: {
      amount: 0,
      isEnabled: false,
      isPercentage: false,
    },
    holidaySurchargeSettings: {
      amount: 0,
      isEnabled: false,
      isPercentage: false,
      publicHolidayDays: [],
      weekdays: [],
    },
    logo: '',
    name: '',
    altName: '',
    address: '',
    phone: '',
    isBigMenuImageUsed: false,
    openHours: {
      closeDaysItems: [],
      openHoursItems: [],
    },
    serviceBell: false,
    serviceTypes: [],
    smsVerify: false,
    takeawayPayment: false,
    themeColor: '',
    urlIdentifier: '',
    timezone: '',
  },
  info: {
    menuId: '',
    tableId: '',
    groupId: '',
  },
  menuIdCategories: '',
  menuVersion: '',
  memberId: '',
  urlHome: '',
  categoryTabId: '',
};

interface IHolidaySurchargeSettings {
  amount: number;
  isPercentage: Boolean;
  isEnabled: Boolean;
  publicHolidayDays: any[];
  weekdays: string[];
}

interface IOnlineSettings {
  dineinTakeawayEnabled: boolean;
  foodCourtPaymentOptional: boolean;
  foodCourtPaymentRequired: boolean;
  foodCourtTakeawayEnabled: boolean;
  hideSpecialRequirement: boolean;
}

export interface IRestaurantSelect {
  address: string;
  altName: string;
  holidaySurcharge: {
    amount: number;
    isEnabled: boolean;
    isPercentage: boolean;
  };
  holidaySurchargeSettings: IHolidaySurchargeSettings;
  isBigMenuImageUsed: boolean;
  isDineMobileOptional: boolean;
  latitude: number;
  logo: string;
  longitude: number;
  name: string;
  onlineSettings: IOnlineSettings;
  openHours: IOpenHours;
  orderAheadDisabled: false;
  phone: string;
  redirectUrl: string;
  serviceBell: boolean;
  serviceTypes: string[];
  smsVerify: false;
  takeawayPayment: boolean;
  themeColor: string;
  timezone: string;
  urlIdentifier: string;
}

export interface IMenuParams {
  menuId: string;
  tableId: string;
  groupId?: string;
  restaurantName?: string | undefined;
}

interface IGroupStore {
  groupState: GroupState;
  requestServiceBell: ({ menuId, tableId, serviceType }: IServiceBell) => void;
  updateInactiveGroup: (action) => void;
  checkOrderingGroupDisabledFunc: (action) => void;
  getAllGroupRestaurants: (
    groupId: string,
    eventTracker: (category: EEventCategory, action: EEventAction, label: string) => Promise<any>,
  ) => void;
  saveInfoGroup: (info) => void;
  setUrlHome: (urlHome: string, menuId: string, tableId: string) => void;
  setMemberId: (memberId: string) => void;
  getMenuQuery: (
    menuId: string,
    tableId: string,
    groupId: string,
    eventTracker: (category: EEventCategory, action: EEventAction, label: string) => Promise<any>,
  ) => QueryFunction<unknown, 'getMenu'>;
  activeCategoryTab: (tabId: string) => void;
}

export const useGroupStore = create<IGroupStore>(
  (persist as MyPersist<IGroupStore | any>)(
    (set, get) => ({
      groupState: initialState,
      getMenuQuery: async (
        menuId: string,
        tableId: string,
        groupId: string,
        eventTracker: (
          category: EEventCategory,
          action: EEventAction,
          label: string,
        ) => Promise<any>,
      ) => {
        try {
          handleThrowErrorNull(menuId, tableId);
          const params = {
            menuId,
            tableId,
          };
          const menuVersion = get().groupState.menuVersion;
          set(
            produce((state: IGroupStore) => {
              state.groupState.status = EFetchingStatus.LOADING;
            }),
          );
          const response = await getMenusQuery(menuId, menuVersion, params);
          const saveInfoGroup = get().saveInfoGroup;
          saveInfoGroup({ groupId, menuId, tableId });
          const data = await response?.data;
          const checkSameVersion = data?.isSameVersion;
          let menu: ICategory[] = [];
          if (checkSameVersion) {
            const menuLocal = get().groupState.categories;
            menu = menuLocal;
          } else {
            menu = data?.menu?.categories;
          }
          const menuSort = menu?.sort(
            (item1: ICategory, item2: ICategory) => item1.sort - item2.sort,
          );
          set(
            produce((state: IGroupStore) => {
              state.groupState.status = EFetchingStatus.SUCCEEDED;
              state.groupState.categories = menuSort;
              state.groupState.isDineInTable = data.isDineInTable;
              state.groupState.restaurant = data.restaurant;
              state.groupState.tableId = data.tableId;
              state.groupState.menuVersion = data.version;
              state.groupState.menuIdCategories = data.menuId;
            }),
          );
          setTimeZoneStorage(data.restaurant.timezone);
        } catch (error: any) {
          set(
            produce((state: IGroupStore) => {
              state.groupState.status = EFetchingStatus.FAILED;
              state.groupState.error = error?.message;
            }),
          );
          const errorData: IHandleErrorProps = {
            logErrorData: {
              endpoint: 'getMenuQuery',
              menuId,
              orderNumber: '',
              tableId,
              error,
              fileName: 'groupSlice',
              functionName: 'getMenuQueryAPI',
            },
            eventTracker,
          };
          handleError(errorData);
        }
      },
      updateInactiveGroup: (action) => {
        set(
          produce((state: IGroupStore) => {
            state.groupState.inactiveMenu = action;
          }),
        );
      },
      checkOrderingGroupDisabledFunc: (action) => {
        set(
          produce((state: IGroupStore) => {
            state.groupState.isOrderingDisabled = action;
          }),
        );
      },
      requestServiceBell: async ({ menuId, tableId, serviceType, eventTracker }: IServiceBell) => {
        set(
          produce((state: IGroupStore) => {
            state.groupState.status = EFetchingStatus.LOADING;
          }),
        );
        try {
          handleThrowErrorNull(tableId, menuId);
          await requestServiceBellAPI(menuId, tableId, serviceType);
          set(
            produce((state: IGroupStore) => {
              state.groupState.status = EFetchingStatus.SUCCEEDED;
            }),
          );
          toast.success(i18n.t(MESSAGE_SERVICE_BELL_REQUEST_SUCCESS));
        } catch (error: any) {
          set(
            produce((state: IGroupStore) => {
              state.groupState.status = EFetchingStatus.FAILED;
              state.groupState.error = error?.message;
            }),
          );
          const errorData: IHandleErrorProps = {
            logErrorData: {
              endpoint: 'newMessage',
              menuId,
              orderNumber: '',
              tableId,
              error,
              fileName: 'groupSlice',
              functionName: 'requestServiceBellAPI',
            },
            eventTracker,
          };
          handleError(errorData);
          toast.error(i18n.t(MESSAGE_SERVICE_BELL_REQUEST_ERROR));
        }
      },
      getAllGroupRestaurants: async (
        groupId: string,
        eventTracker: (
          category: EEventCategory,
          action: EEventAction,
          label: string,
        ) => Promise<any>,
      ) => {
        set(
          produce((state: IGroupStore) => {
            state.groupState.isLoading = true;
          }),
        );
        try {
          const res = await getAllGroupRestaurantsAPI(groupId);
          const data = await res.data;
          set(
            produce((state: IGroupStore) => {
              state.groupState.isLoading = false;
              state.groupState.groupRestaurants = data;
            }),
          );
        } catch (error: any) {
          set(
            produce((state: IGroupStore) => {
              state.groupState.isLoading = false;
              state.groupState.groupRestaurants = [];
            }),
          );
          const errorData: IHandleErrorProps = {
            logErrorData: {
              endpoint: `/groupRestaurants/${groupId}`,
              menuId: '',
              orderNumber: '',
              tableId: '',
              error,
              fileName: 'groupSlice',
              functionName: 'getAllGroupRestaurantsAPI',
            },
            eventTracker,
          };
          handleError(errorData);
        }
      },
      saveInfoGroup: (info) => {
        set(
          produce((state: IGroupStore) => {
            state.groupState.info = info;
          }),
        );
      },
      setUrlHome: (url: string, menuId: string, tableId: string) => {
        if (isNull([menuId, tableId])) return;
        saveUrlHome(urlHomeGroup(replaceWhiteSpace(url)));
        set(
          produce((state: IGroupStore) => {
            state.groupState.urlHome = urlHome(replaceWhiteSpace(url));
          }),
        );
      },
      setMemberId: (memberId: string) => {
        set(
          produce((state: IGroupStore) => {
            state.groupState.memberId = memberId;
          }),
        );
      },
      activeCategoryTab: (tabId: string) => {
        set(
          produce((state: IGroupStore) => {
            state.groupState.categoryTabId = tabId;
          }),
        );
      },
    }),
    {
      name: renderName('group'), // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      partialize: (state) => {
        const result = dataPersist(state.groupState);
        return {
          groupState: result,
        };
      },
    },
  ),
);
