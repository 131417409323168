import { Container, LinearProgress, makeStyles } from '@material-ui/core';
import { useStore } from 'features/home/store/homeSlice';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RootRouter from 'router/RootRouter';
import LoadingPageTemplate from 'templates/LoadingPageTemplate';
import { theme } from 'theme';
import { EFetchingStatus } from 'types/globalType';
import { version } from 'utils/version';
import './App.css';
import { shallow } from 'zustand/shallow';
import { handleClearCart, isCrawler } from 'utils/restaurantFeatures';
import ErrorBoundary from 'components/ErrorBoundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useGroupStore } from 'features/group/store/groupSlice';
import { useCartStore } from 'features/cart/store/cartSlice';
import moment from 'moment';
import { useGroupCartStore } from 'features/cart/store/cartSliceGroup';
import { URL_HOME } from 'submodules/OrderCore/src/constants';
const timeClearCart: number = 60; //minutes

const useStyles = makeStyles({
  fullHeight: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    padding: '0 20px !important',
  },
});
function App() {
  const now = new Date();
  const [isBot, setIsBot] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (isCrawler(userAgent)) {
      setIsBot(true);
    }
  }, []);
  const classes = useStyles();
  const { status, info: infoHome } = useStore(
    (state) => ({
      status: state.homeState.status,
      info: state.homeState.info,
    }),
    shallow,
  );

  const { info: infoGroup } = useGroupStore(
    (state) => ({
      info: state.groupState.info,
    }),
    shallow,
  );

  const { cart: cartHome, updateOrderInCart } = useCartStore(
    (state) => ({
      cart: state.cartState.cart,
      updateOrderInCart: state.updateOrderInCart,
    }),
    shallow,
  );

  const { cart: cartGroup, updateOrderInCartOrder } = useGroupCartStore(
    (state) => ({
      cart: state.cartGroupState.cart,
      updateOrderInCartOrder: state.updateOrderInCartOrder,
    }),
    shallow,
  );

  const versionStorage = localStorage.getItem('version');
  const handleClearCache = async () => {
    if ('caches' in window) {
      const keyStorages = await caches.keys();
      await Promise.all(keyStorages.map((name) => caches.delete(name)));
      window.location.reload();
    }
    handleSetVersion();
    for (let key in localStorage) {
      if (key !== URL_HOME) localStorage.removeItem(key);
    }
    return;
  };

  const handleSetVersion = () => {
    localStorage.setItem('version', version);
  };

  const isGroup = window.location.pathname.split('/')[1] === 'group';

  const info = isGroup ? infoGroup : infoHome;
  const cart = isGroup ? cartGroup : cartHome;

  const myStore = useCartStore();

  const handleClearCartAfterTime = () => {
    const lastUpdateCart = myStore.cartState?.cart?.lastUpdated;
    if (moment(now).diff(moment(lastUpdateCart), 'minutes') > timeClearCart) {
      handleClearCart(info, cart, updateOrderInCart, updateOrderInCartOrder, cartHome);
    }
  };

  const queryClient = new QueryClient({});

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    handleClearCartAfterTime();
    if (!versionStorage) {
      handleSetVersion();
      return;
    }
    if (versionStorage !== version) {
      handleClearCache();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isBot ? (
        <h1>Sorry, web crawlers are not allowed.</h1>
      ) : (
        <Container maxWidth={false} className={classes.fullHeight} id="scrollableDiv">
          <React.Suspense
            fallback={
              status === EFetchingStatus.LOADING || status === EFetchingStatus.FAILED ? (
                <LoadingPageTemplate />
              ) : (
                <LinearProgress />
              )
            }
          >
            <ErrorBoundary>
              <QueryClientProvider client={queryClient}>
                <RootRouter />
              </QueryClientProvider>
            </ErrorBoundary>
          </React.Suspense>
          <ToastContainer
            theme="colored"
            position="bottom-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ fontSize: 12 }}
          />
        </Container>
      )}
    </>
  );
}

export default App;
