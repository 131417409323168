export type ExampleGlobalType = {
  value: string;
};

export interface ILogErrorPayload {
  title: string;
  message: string;
}

export const MEMBERID = 'memberId';
export const ORDER_SUCCESS = 'orderSuccess';
export const ONLINE = 'online';
export const TYPE = 'type';

export const ZH_CN = 'zh-CN';
export const ZH_SG = 'zh-SG';
export const ZH_TW = 'zh-TW';
export const ZH_HK = 'zh-HK';

export enum ELANGUAGE {
  ENGLISH = 'English',
  CHINESE = 'Chinese',
}
export enum EFetchingStatus {
  LOADING = 'LOADING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum ETypePayment {
  CANCEL = 'cancel',
  SUCCESS = 'success',
  ERROR = 'error',
}
