import { PaymentMethodType } from 'features/payment/containers/AddInformationContainer';
import { IMenuParams } from './../../home/store/homeSlice';
import { EFetchingStatus } from 'types/globalType';
import { TTableId } from 'features/home/types/homeTypes';
import { EEventAction, EEventCategory } from 'utils/constants';
import { PaymentMethodsResponseObject } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';

export type TPickupTimeType = 'now' | 'chooseTime';

export enum EPickupDelivery {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
}

export interface IOrderHistoryItem {
  order: IOrderCart;
  reference: string;
  tableId: TTableId;
}

export interface IOrderHistory {
  items: IOrderHistoryItem[];
}

export interface CartState {
  cart: ICart;
  status: EFetchingStatus;
  error?: string;
  placeOrderResponse: IPlaceOrder;
  reference: string;
  isDuplicate: boolean;
  placeOrderResponseAdyen: IPlaceOrderResponseAdyen;
  urlHome: string;
  orderHistory: IOrderHistory;
}

export interface ISplit {
  account: string;
  amount: {
    currency: string;
    value: number;
  };
  reference: string;
  type: string;
}

export interface IPlaceOrderResponseAdyen {
  environment: string;
  clientKey: string;
  isAdyenPayment: boolean;
  locale: string;
  dateStr: string;
  countryCode: string;
  yearStr: string;
  paymentMethodResults?: PaymentMethodsResponseObject;
  paymentResult: {
    amount: IAmount;
    channel: string;
    countryCode: string;
    expiresAt: string;
    id: string;
    merchantAccount: string;
    reference: string;
    returnUrl: string;
    sessionData: string;
    shopperLocale: string;
    splits: ISplit[];
  };
  reference: string;
}

export interface IAmount {
  value: number;
  currency: string;
}

export interface IPlaceOrder {
  isTillPayment: boolean;
  payUrl: string;
  reference: string;
  sessionId: string;
  stripeAccount: string;
  isAdyenPayment?: boolean;
}

export interface ICart {
  lastUpdated?: Date;
  user: IUserCart;
  order: IOrderCart;
  restaurant: IRestaurantCart;
  menuId: string;
  browser: string;
  mobileModel: string;
  verifyCode: string;
  sid: string;
  recaptcha: string;
  memberId?: string;
  cardId?: string;
}

export interface IUserCart {
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  postcode: string;
  foodAllergies: string;
  geolocation: any;
  guestNum: string | number;
}

export interface IRestaurantCart {
  restaurantId: string;
  tableId: string;
}

export interface IOrderCart {
  items: IItemCart[];
  pickupTimeType: TPickupTimeType;
  pickupTime: string;
  selectedDate: string;
  total: number;
  paymentType: PaymentMethodType | null;
  isPaymentRequired: boolean;
  type: string;
  realTableId: string;
  chooseType: EPickupDelivery;
  notes: string;
  orderTime: string;
  restaurant?: string;
  orderNumber: string;
}

export interface IItemCart {
  dish: IDishCart;
  isTakeaway: boolean;
  options: IOptionCart[];
  id: string;
  specialInstructions: string;
  quantity: number;
  isRequiredSelected: boolean;
  subTotal: number;
  size: any;
}

export interface IAddItemCart {
  item: IItemCart;
  info: IMenuParams;
}

export interface IOptionCart {
  id: string;
  menuId?: string;
  price: number;
  name: string;
  groupId: string;
  sort: number;
  default: boolean;
  quantity: number;
  shortName: string;
}

export interface IDishCart {
  groups: IGroupCart[];
  sizes: any;
  hidden_until?: any;
  altName: string;
  thumbImageUrl?: string;
  isTakeawayDisabled?: boolean;
  description: string;
  price: number;
  menuId: string;
  name: string;
  imageUrl: string;
  active_end?: any;
  active_begin?: any;
  ingredients: string;
  active: boolean;
  categoryId: string;
  sort: number;
  id: string;
}

export interface IGroupCart {
  updated: IDateCart;
  createdAt: IDateCart;
  options: IOptionCart[];
  id: string;
  menuId: string;
  required: boolean;
  force_min: number;
  force_max: number;
  name: string;
  allow_quantity: boolean;
}

export interface IDateCart {
  _seconds: number;
  _nanoseconds: number;
}

export interface ISendCodeProps {
  mobilePhone: string;
  recaptcha: string;
  restaurantId: string;
  tableId: string;
  eventTracker: (category: EEventCategory, action: EEventAction, label: string) => Promise<any>;
}
