/* eslint-disable no-console */
import moment from 'moment-timezone';
import { PaymentMethodType } from 'features/payment/containers/AddInformationContainer';
import { ref, onValue } from 'firebase/database';
import { dbApp } from './firebaseConfig';
import {
  EEventAction,
  EEventCategory,
  EFieldName,
  ETypeMenu,
  MESSAGE_ADD_TO_CART_ITEMS_SUCCESS,
  MESSAGE_ADD_TO_CART_ITEM_SUCCESS,
  MESSAGE_CHOOSE_SIZE,
  TOAST_TIME_MESSAGE_ADD_TO_CART,
  appConstants,
  errorNull,
  invalidCodeRecaptcha,
  stringInvalid,
  timeOrderHistory,
} from './constants';
import {
  checkDineMobileOptional,
  decodeRestaurantId,
  disableNumberOfGuest,
  inactiveMenuIds,
  isDelivery,
  isPickupAndDelivery,
} from 'submodules/OrderCore';
import { IProductItem } from 'features/home/types/homeTypes';
import { ISizeSelect } from 'features/order/components/orderGroupOptions/OrderGroupOptions';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { EPickupDelivery, ICart } from 'features/cart/types/CartTypes';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { Information } from 'features/group/pages/AddInformation/containers/AddInformationContainerGroup';
import { v4 as uuidv4 } from 'uuid';
import API from 'api/api';
import { EFetchingStatus, ILogErrorPayload, ONLINE, ORDER_SUCCESS } from 'types/globalType';
import { GroupState, IMenuParams } from 'features/group/store/groupSlice';
import {
  CartItem,
  IGroupCartStore,
  IInfoCartGroup,
  IPlaceOrderProps,
} from 'features/cart/store/cartSliceGroup';
import { version } from 'utils/version';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { IHomeCartStore } from 'features/cart/store/cartSlice';
import { loadUrlHome } from './localStorage';
import { IOrderOptionsData } from 'features/step-menu/pages/OrderDetail/types/OrderTypes';
import { EKeySetValue, IOrderData } from 'features/order/types/OrderTypes';
import { HomeDialogProps, HomeState } from 'features/home/store/homeSlice';

export const getKeyRestaurant = (urlHome: string) => {
  if (!urlHome) return '';
  const keysUrl = urlHome.split('/');
  if (keysUrl.length < 4) return '';
  const keyRestaurant = `/${keysUrl[2]}`;
  return keyRestaurant;
};

export const checkInactiveMenuHome = (menuId: string, updateInactiveFunc: any): any => {
  let inactiveIds;
  let refMenu = ref(dbApp, `inactiveMenus/${decodeRestaurantId(menuId)}`);
  onValue(refMenu, (snapshot) => {
    if (snapshot && snapshot.val() && snapshot.val().dishIds && snapshot.val().dishIds.length > 0) {
      updateInactiveFunc(inactiveMenuIds(snapshot.val().dishIds));
    } else {
      inactiveIds = [];
    }
  });
  return inactiveIds;
};

export const isHomeDialogShown = (
  restaurantId: string,
  checkHomeDialogShownFunc: (dialogSettings: HomeDialogProps) => void,
) => {
  let isDialogShown: boolean = false;
  let content: string = '';
  let title: string = '';
  const restaurantIdSplit = restaurantId.includes('_') ? restaurantId.split('_')[0] : restaurantId;
  let refMenuSettings = ref(dbApp, `menuSettings/${restaurantIdSplit}`);
  onValue(refMenuSettings, (snapshot) => {
    if (
      snapshot &&
      snapshot.val() &&
      snapshot.val().showDialog !== undefined &&
      snapshot.val().showDialog !== null
    ) {
      isDialogShown = snapshot.val().showDialog;
      content = snapshot.val().dialogText;
      title = snapshot.val().dialogTitle;
      const dialogVersion = snapshot.val().dialogVersion;
      const currentDialogVersion = sessionStorage.getItem('dialogVersion');
      if (currentDialogVersion && currentDialogVersion === dialogVersion) {
        checkHomeDialogShownFunc({
          isDialogShown: false,
          dialogContent: '',
          dialogTitle: '',
          dialogVersion: '',
        });
        return;
      }

      checkHomeDialogShownFunc({
        isDialogShown,
        dialogContent: content,
        dialogTitle: title,
        dialogVersion,
      });
    } else {
      checkHomeDialogShownFunc({
        isDialogShown: false,
        dialogContent: '',
        dialogTitle: '',
        dialogVersion: '',
      });
    }
  });
};

export const checkOrderingDisabled = (
  restaurantId: string,
  menuTableId: string,
  checkOrderingDisabledFunc: any,
): boolean => {
  let isOrderingDisabled: boolean | undefined;
  const tableId = menuTableId.includes(';') ? menuTableId.split(';')[0] : menuTableId;
  const restaurantIdSplit = restaurantId.includes('_') ? restaurantId.split('_')[0] : restaurantId;
  let refQrCode = ref(dbApp, `messages/${restaurantIdSplit}/QrCodeSettings/${tableId}`);
  onValue(refQrCode, (snapshot) => {
    const todayString = moment().locale('au').format('DD/MM/YYYY');
    if (
      snapshot &&
      snapshot.val() &&
      (snapshot.val().isOrderingDisabled === true || snapshot.val().disabledDate === todayString)
    ) {
      checkOrderingDisabledFunc(true);
      isOrderingDisabled = true;
    } else {
      checkOrderingDisabledFunc(false);
      isOrderingDisabled = false;
    }
  });
  return isOrderingDisabled ?? false;
};

export function checkDefaultPaymentMethod(): PaymentMethodType {
  // const result = browserName === 'WeChat' ? PaymentMethodType.WECHAT : PaymentMethodType.BANK_CARD;
  // return result;
  return PaymentMethodType.BANK_CARD;
}

export const messageErrorForm = (
  information: any,
  pickupDeliveryType: any,
  tableId: any,
  codeVerify: any,
  isDineInTable: boolean,
  restaurant: any,
  pickupTime: string,
  selectedDate: string,
  pickupTimeType: string,
  countryCode: CountryCode | undefined,
) => {
  let message: string[] = [];
  if (!information.name) {
    message = [...message, EFieldName.NAME];
  }
  if (
    information.numberOfGuests === '' &&
    !disableNumberOfGuest(tableId) &&
    !checkDineMobileOptional(
      isDineInTable,
      restaurant.isDineMobileOptional,
      restaurant?.onlineSettings?.isDineInGuestNumberRequired,
    )
  ) {
    message = [...message, EFieldName.NUMBER_OF_GUEST];
  }
  if (
    checkValidatePhoneNumber(information, countryCode) &&
    !checkDineMobileOptional(
      isDineInTable,
      restaurant.isDineMobileOptional,
      restaurant?.onlineSettings?.isDineInMobilePhoneRequired,
    )
  ) {
    message = [...message, EFieldName.PHONE_NUMBER];
  }
  if (codeVerify.status && restaurant.smsVerify) {
    message = [...message, EFieldName.CODE];
  }
  if (!information.postcode && (isPickupAndDelivery(tableId) || isDelivery(tableId))) {
    message = [...message, EFieldName.POST_CODE];
  }
  if (information.address === '' && (isDelivery(pickupDeliveryType) || isDelivery(tableId))) {
    message = [...message, EFieldName.ADDRESS];
  }
  if (pickupTimeType === 'chooseTime' && pickupTime === '') {
    message = [...message, EFieldName.TIME];
  }
  if (pickupTimeType === 'chooseTime' && selectedDate === '') {
    message = [...message, EFieldName.DATE];
  }
  const messageString = message.toString().replaceAll(',', ' , ');
  return `Field ${messageString} is invalid`;
};

export const getMessageAddToCart = (quantity) => {
  const message =
    quantity === 1 ? MESSAGE_ADD_TO_CART_ITEM_SUCCESS : MESSAGE_ADD_TO_CART_ITEMS_SUCCESS;
  return message;
};

export const checkOptionSizeDefault = (sizes: ISizeSelect[]) => {
  const sizeDefault = sizes.find((size: ISizeSelect) => {
    return size?.default;
  });
  return sizeDefault || null;
};

export const handleCheckSize = (
  productData: IProductItem,
  sizeSelected: ISizeSelect | null,
  setvalue: (value, key: EKeySetValue) => void,
  handleCloseChooseSize: () => void,
) => {
  if (!productData.sizes || sizeSelected) {
    handleCloseChooseSize();
    return;
  }
  const defaultSize = checkOptionSizeDefault(productData.sizes);
  if (defaultSize) {
    handleCloseChooseSize();
    setvalue(defaultSize, EKeySetValue.SET_SIZE_SELECTED);
  } else {
    toast.error(i18n.t(MESSAGE_CHOOSE_SIZE), {
      autoClose: TOAST_TIME_MESSAGE_ADD_TO_CART,
    });
  }
};

export const checkValidateForm = (
  information: any,
  isDineInTable: any,
  restaurant: any,
  tableId: any,
  pickupTimeType: any,
  pickupTime: any,
  selectedDate: any,
  pickupDeliveryType: any,
  countryCode: CountryCode | undefined,
) => {
  return (
    information.name === '' ||
    (information.mobile === '' &&
      !checkDineMobileOptional(
        isDineInTable,
        restaurant.isDineMobileOptional,
        restaurant?.onlineSettings?.isDineInMobilePhoneRequired,
      )) ||
    (information.numberOfGuests === '' &&
      !disableNumberOfGuest(tableId) &&
      !checkDineMobileOptional(
        isDineInTable,
        restaurant.isDineMobileOptional,
        restaurant?.onlineSettings?.isDineInGuestNumberRequired,
      )) ||
    (pickupTimeType === 'chooseTime' && pickupTime === '') ||
    (pickupTimeType === 'chooseTime' && selectedDate === '') ||
    (checkValidatePhoneNumber(information, countryCode) &&
      information.mobile !== '' &&
      checkDineMobileOptional(
        isDineInTable,
        restaurant.isDineMobileOptional,
        restaurant?.onlineSettings?.isDineInMobilePhoneRequired,
      )) ||
    (checkValidatePhoneNumber(information, countryCode) &&
      !checkDineMobileOptional(
        isDineInTable,
        restaurant.isDineMobileOptional,
        restaurant?.onlineSettings?.isDineInMobilePhoneRequired,
      )) ||
    ((pickupDeliveryType === EPickupDelivery.DELIVERY || isDelivery(tableId)) &&
      information.address === '') ||
    (isPickupAndDelivery(tableId) && information.postcode === '')
  );
};

export const checkItemSoldOut = (cart, inactiveMenu: any[]) => {
  const temp = cart?.order?.items?.find((item) => {
    return inactiveMenu?.includes(item.dish.id);
  });
  return temp;
};

export const handleClearCart = (
  info: IMenuParams,
  cart: ICart | CartItem[],
  updateOrderInCart: (action) => void,
  updateOrderInCartOrder: (action) => void,
  cartHome: ICart,
) => {
  if (info && info?.groupId) {
    updateOrderInCartOrder({ info });
  } else {
    if (cart?.hasOwnProperty('order')) {
      updateOrderInCart({
        order: { ...cartHome.order, total: 0, items: [] },
        info,
      });
    }
  }
};

export function isValidAustralianMobileNumber(phoneNumber) {
  if (typeof phoneNumber !== 'string') {
    return false;
  }
  const phoneNumberRegex = /^(04|4)\d{8}$/;
  return phoneNumberRegex.test(phoneNumber);
}

export const checkValidatePhoneNumber = (
  information: Information,
  countryCode: CountryCode | undefined,
) => {
  if (countryCode === 'AU') {
    return !isValidAustralianMobileNumber(information.mobile.slice(3));
  }
  return !isValidPhoneNumber(information.mobile);
};

export const shortUUID = () => {
  const uuid = uuidv4();
  // remove decoration
  const hexString = uuid.replace(/-/g, '');

  const base64String = Buffer.from(hexString, 'hex').toString('base64');
  // remove special characters from the base64 string
  const shortUUID = base64String.replace(/[^a-zA-Z0-9]/g, '');

  return shortUUID;
};

export const logError = async (payload: ILogErrorPayload) => {
  try {
    if (process.env.NODE_ENV === 'production') {
      await API.post('/utils/logError', payload);
    }
  } catch (error) {
    console.error(error?.message);
  }
};

export const renderErrorData = ({
  endpoint,
  menuId,
  orderNumber,
  tableId,
  error,
  fileName,
  functionName,
  payload,
}: ILogErrorData) => {
  try {
    let errorData = {
      title: {
        restaurantId: menuId,
        tableId: tableId,
      },
      message: {
        message: error?.message || '',
        url: window?.location?.href || '',
        functionName: functionName,
        fileName: fileName,
        userAgent: navigator.userAgent.toLowerCase(),
        version: version,
        urlHomeStorage: loadUrlHome(),
      },
    };
    if (orderNumber) {
      errorData.title['orderNumber'] = orderNumber;
    }
    if (endpoint) {
      errorData.title['endpoint'] = endpoint;
    }
    if (error?.response?.data) {
      errorData.message['responseErrorApi'] = error?.response?.data;
    }
    if (payload) {
      errorData.message['payload'] = payload;
    }
    return {
      title: JSON.stringify(errorData.title),
      message: JSON.stringify(errorData.message),
    };
  } catch (error) {
    return {
      title: '',
      message: '',
    };
  }
};

export function isCrawler(userAgent) {
  const bots = ['bot', 'crawler', 'spider', 'search', 'googlebot', 'bingbot', 'yandexbot'];
  for (let i = 0; i < bots.length; i++) {
    if (userAgent.indexOf(bots[i]) > -1) {
      return true;
    }
  }
  return false;
}

export const titleHeader = (name: string) => {
  return `Orderbuddy-${name}`;
};

export function detectMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export const hasLength = (array: any[]) => {
  return array && array.length !== 0;
};

export const replaceWhiteSpace = (text: string) => {
  return text.replaceAll(' ', '%20');
};

export const isNull = (arrayCheck: string[]) => {
  return arrayCheck.some((item) => {
    return !item || stringInvalid.includes(item);
  });
};

export interface ILogErrorData {
  endpoint?: string;
  menuId?: string;
  orderNumber?: string | undefined;
  tableId?: string;
  error: any;
  fileName: string;
  functionName?: string;
  payload?: string;
}

export interface IHandleErrorProps {
  logErrorData: ILogErrorData;
  eventTracker?: (category: EEventCategory, action: EEventAction, label: string) => Promise<any>;
}

export const handleError = ({ logErrorData, eventTracker }: IHandleErrorProps) => {
  const errorData = renderErrorData(logErrorData);

  logError({
    title: errorData.title,
    message: errorData.message,
  });
  if (eventTracker) {
    eventTracker(EEventCategory.ERROR_EVENT, EEventAction.ERROR, errorData.message || '');
  }
};

export const handleThrowErrorNull = (menuId: string, tableId: string) => {
  if (isNull([tableId, menuId])) {
    throw new Error(errorNull);
  }
};

export const isSameString = (
  string1: string | undefined | null,
  string2: string | undefined | null,
): boolean => {
  if (!string1 || !string2) return false;
  return string1.trim().toLowerCase() === string2.trim().toLowerCase();
};

export const getRecaptchaToken = async (
  executeRecaptcha: ((action?: string | undefined) => Promise<string>) | undefined,
) => {
  let recaptcha = invalidCodeRecaptcha;
  try {
    if (!executeRecaptcha) {
    } else {
      const recaptchaToken = await executeRecaptcha('handleReCaptchaVerify');
      if (!recaptchaToken) {
      } else {
        recaptcha = recaptchaToken;
      }
    }
    return recaptcha;
  } catch (error) {
    return recaptcha;
  }
};

export const getOrderHistoryOnTime = (array: any[]) => {
  if (array.length === 0) return [];
  const now = new Date();
  const temp = array?.filter((itemHistory) => {
    const orderTime = itemHistory?.order?.orderTime;
    return (
      moment(now).diff(moment(orderTime, appConstants.dateTimeFormat), 'minutes') < timeOrderHistory
    );
  });
  return temp;
};

export const urlHomeGroup = (pathname: string) => {
  const keys = pathname.split('/');
  if (keys.length < 5) return pathname;
  return `/group/${pathname.split('/')[2]}/${pathname.split('/')[3]}/${pathname.split('/')[4]}`;
};
export interface IHandlePlaceOrder {
  store: IHomeCartStore | IGroupCartStore;
  placeOrderMutation: UseMutationResult<AxiosResponse<any>, any, any, unknown>;
  options: IPlaceOrderProps;
}

export const handlePlaceOrder = async ({
  store,
  placeOrderMutation,
  options,
}: IHandlePlaceOrder) => {
  try {
    store.setStatus(EFetchingStatus.LOADING);
    const mutated = await placeOrderMutation.mutateAsync(options);
    store.placeOrder(mutated);
    store.setStatus(EFetchingStatus.SUCCEEDED);
  } catch (err) {
    store.handleErrorMutation(err, options);
  }
};

export const checkGroup = () => {
  const urlHomeStorage = loadUrlHome();
  if (!urlHomeStorage) return false;
  const keys = urlHomeStorage.split('/');
  if (keys.length < 2) return false;
  const type = keys[1];
  return type === ETypeMenu.GROUP;
};

export const initialOrderData = (productDataNoEmpty: any) => {
  return {
    groups: (productDataNoEmpty || []).map((item: any) => ({
      id: item.id,
      options: item?.options?.map((option) => ({
        selected: option.default || false,
        id: option.id,
        quantity: option.default ? 1 : 0,
        image: option.image?.replaceAll(' ', '%20'),
        price: option.price,
        name: option.name,
        groupId: item.id,
        sort: option.sort,
        default: option.default,
        menuId: option.menuId,
        menuItemId: option.menuItemId || '',
        shortName: option.shortName,
      })),
    })),
  };
};

export interface IHandleAddToCartProps {
  productData: IProductItem | undefined;
  orderData: IOrderData;
  sizeSelected: ISizeSelect | null;
  quantity: number;
  isSwitchTakeaway: boolean;
  note: string;
  eventTracker: (category: EEventCategory, action: EEventAction, label: string) => Promise<any>;
  addToCart: (action: any) => void;
  info: IMenuParams | IInfoCartGroup;
  history: any;
  handleLogError: (error: any, functionName: string) => void;
  state: HomeState | GroupState;
}

export const handleAddToCart = ({
  productData,
  orderData,
  sizeSelected,
  quantity,
  isSwitchTakeaway,
  note,
  eventTracker,
  addToCart,
  handleLogError,
  history,
  info,
  state,
}: IHandleAddToCartProps) => {
  if (!productData) return;
  const urlHome = loadUrlHome();
  try {
    let groupTotal = 0;
    let options: IOrderOptionsData[] = [];
    orderData.groups.map((group) => {
      let itemTotal = 0;
      group.options.map((option) => {
        if (option.quantity > 0) {
          options.push(option);
          if (option.price !== 0) {
            itemTotal += option.price * option.quantity;
          }
        }
      });
      groupTotal += itemTotal;
    });
    const priceProduct = sizeSelected?.name ? sizeSelected.price : productData?.price;
    const subTotal = groupTotal + priceProduct;
    const data = {
      quantity,
      id: uuidv4(),
      isTakeaway: isSwitchTakeaway,
      specialInstructions: note,
      isRequiredSelected: true,
      subTotal: Number(subTotal.toFixed(2)),
      size: sizeSelected || checkOptionSizeDefault(productData.sizes),
      options,
      dish: {
        ...productData,
        hide_instructions: false,
        menu_category_id: '',
        gstFree: false,
      },
    };
    eventTracker(EEventCategory.ADD_CART_EVENT, EEventAction.ADD, data?.dish?.name);
    addToCart({ item: data, info });
    setTimeout(() => {
      const elementAnimation = document.getElementById(productData.id);
      if (elementAnimation) {
        elementAnimation.click();
      }
    }, 0);
    handleRouter({
      history,
      state,
      customUrl: '',
      fileName: 'restaurantFeatures',
    });
    history.push(urlHome);
  } catch (error) {
    handleLogError(error, 'handleAddToCart');
  }
};

export const getHeightInformation = () => {
  const informationElement = document.getElementById('item-information');
  if (informationElement?.clientHeight) {
    return informationElement.clientHeight;
  }
  return 0;
};

export const getUrl = (urlHomeStore: string) => {
  return urlHomeStore || loadUrlHome();
};

export const isValidUrl = (url: string) => {
  if (!url) return false;
  const keys = url.split('/');
  const isInvalid = keys.some((key: string) => {
    return stringInvalid.includes(key);
  });
  return !isInvalid;
};

interface IHandleRouterProps {
  history: any;
  state: HomeState | GroupState;
  customUrl?: string;
  fileName: string;
}

export const handleRouter = ({ history, state, customUrl, fileName }: IHandleRouterProps) => {
  const urlHome = getUrl(state.urlHome);
  if (!isValidUrl(`${urlHome}${customUrl}`)) {
    const errorData: IHandleErrorProps = {
      logErrorData: {
        error: new Error(`url invalid url: ${urlHome}${customUrl}`),
        fileName,
      },
    };
    handleError(errorData);
    history.push('/404');
    return;
  }
  const urlPush = !customUrl ? urlHome : `${urlHome}${customUrl}`;
  history.push(urlPush);
  return;
};

interface IUpdateUrlHomeStoreProps {
  state: HomeState | GroupState;
  setUrlHome: (urlHome: string, menuId: string, tableId: string) => void;
  menuId: string;
  tableId: string;
}

export const updateUrlHomeStore = ({
  state,
  setUrlHome,
  menuId,
  tableId,
}: IUpdateUrlHomeStoreProps) => {
  const pathname = window.location.pathname;
  const urlStore = state.urlHome;
  if (urlStore || !pathname) return;
  const keys = pathname.split('/');
  if (keys.length < 2) return;
  let customUrlHome = pathname;
  if (keys[1] === ORDER_SUCCESS) customUrlHome = pathname.replace(ORDER_SUCCESS, ONLINE);
  setUrlHome(customUrlHome, menuId, tableId);
};
