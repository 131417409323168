/* eslint-disable indent */
import { browserName } from "react-device-detect";
import { EFieldName, EPickupDelivery, IRestaurant, PaymentMethodType } from "../types";
import {
  checkDineMobileOptional,
  disableNumberOfGuest,
  isDelivery,
  isMobile,
  isPickupAndDelivery,
} from "./restaurantCommon";

export const checkDefaultPaymentMethod = (): PaymentMethodType => {
  const result =
    browserName === "WeChat"
      ? PaymentMethodType.WECHAT
      : PaymentMethodType.BANK_CARD;
  return result;
};
export const messageErrorForm = (
  information: any,
  pickupDeliveryType: any,
  tableId: any,
  codeVerify: any,
  isDineInTable: boolean,
  restaurant: any,
  pickupTime: string,
  selectedDate: string,
  pickupTimeType: string
) => {
  let message: string[] = [];
  if (!information.name) {
    message = [...message, EFieldName.NAME];
  }
  if (
    !information.numberOfGuests &&
    !(
      (isDineInTable &&
        !restaurant?.onlineSettings?.isDineInGuestNumberRequired) ||
      disableNumberOfGuest(tableId)
    )
  ) {
    message = [...message, EFieldName.NUMBER_OF_GUEST];
  }
  if (
    !isMobile(information.mobile) &&
    !(isDineInTable && !restaurant?.onlineSettings?.isDineInMobilePhoneRequired)
  ) {
    message = [...message, EFieldName.PHONE_NUMBER];
  }
  if (codeVerify.status && restaurant.smsVerify) {
    message = [...message, EFieldName.CODE];
  }
  if (
    !information.postcode &&
    (isPickupAndDelivery(tableId) || isDelivery(tableId))
  ) {
    message = [...message, EFieldName.POST_CODE];
  }
  if (
    information.address === "" &&
    (isDelivery(pickupDeliveryType) || isDelivery(tableId))
  ) {
    message = [...message, EFieldName.ADDRESS];
  }
  if (pickupTimeType === "chooseTime" && pickupTime === "") {
    message = [...message, EFieldName.TIME];
  }
  if (pickupTimeType === "chooseTime" && selectedDate === "") {
    message = [...message, EFieldName.DATE];
  }
  const messageString = message.toString().replace(/,/g, " , ");
  return `Field ${messageString} is invalid`;
};

export const checkValidateForm = (
  information: any,
  isDineInTable: any,
  restaurant: any,
  tableId: any,
  pickupTimeType: any,
  pickupTime: any,
  selectedDate: any,
  pickupDeliveryType: any
) => {
  return (
    information.name === "" ||
    (information.mobile === "" &&
      !checkDineMobileOptional(
        isDineInTable,
        restaurant.isDineMobileOptional,
        restaurant?.onlineSettings?.isDineInMobilePhoneRequired
      )) ||
    (information.numberOfGuests === "" &&
      !disableNumberOfGuest(tableId) &&
      !checkDineMobileOptional(
        isDineInTable,
        restaurant.isDineMobileOptional,
        restaurant?.onlineSettings?.isDineInGuestNumberRequired
      )) ||
    (pickupTimeType === "chooseTime" && pickupTime === "") ||
    (pickupTimeType === "chooseTime" && selectedDate === "") ||
    (!isMobile(information.mobile) &&
      information.mobile !== "" &&
      checkDineMobileOptional(
        isDineInTable,
        restaurant.isDineMobileOptional,
        restaurant?.onlineSettings?.isDineInMobilePhoneRequired
      )) ||
    (!isMobile(information.mobile) &&
      !checkDineMobileOptional(
        isDineInTable,
        restaurant.isDineMobileOptional,
        restaurant?.onlineSettings?.isDineInMobilePhoneRequired
      )) ||
    ((pickupDeliveryType === EPickupDelivery.DELIVERY || isDelivery(tableId)) &&
      information.address === "") ||
    (isPickupAndDelivery(tableId) && information.postcode === "")
  );
};

export const isPaypalEnabled = (restaurant: IRestaurant) => {
  if (restaurant?.onlineSettings?.isPaypalEnabled) {
    return true;
  }
  return false;
};

export const isAllergyNotice = (restaurant: IRestaurant) => {
  return (
    restaurant?.onlineSettings?.allergyNotice !== "" &&
    restaurant?.onlineSettings?.allergyNotice
  );
};

export const isSpecialTerms = (restaurant: IRestaurant) => {
  return (
    restaurant?.onlineSettings?.specialTerms &&
    restaurant?.onlineSettings?.specialTerms?.length > 0
  );
};
