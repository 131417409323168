import NotFound from 'components/NotFound/NotFound';
import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { analytics } from 'utils/analytics/analytics';

const HomePage = React.lazy(() => import('features/home/HomeRoute'));
const GroupRoute = React.lazy(() => import('features/group/GroupRoute'));
const StepRoute = React.lazy(() => import('features/step-menu/StepRoute'));
const MenuPage = React.lazy(() => import('features/menu/MenuRoute'));
const OrderSuccessPage = React.lazy(
  () => import('features/step-menu/pages/CheckoutStep/OrderSuccessPage'),
);
const CartPage = React.lazy(() => import('features/cart/CartPage'));
const OrderHistoryPage = React.lazy(() => import('features/home/pages/OrderHistory/OrderHistory'));
const CheckErrorPayment = React.lazy(
  () => import('components/CheckErrorPayment/CheckErrorPayment'),
);

function RootRouter() {
  const history = createBrowserHistory();
  useEffect(() => {
    analytics.page();
  }, [history]);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <NotFound />} />
        <Route path="/group/:groupId/:tableId" render={() => <GroupRoute />} />
        <Route path="/online/:menuId/:tableId" render={() => <HomePage />} />
        <Route path="/step/:menuId/:tableId" render={() => <StepRoute />} />
        <Route
          path="/menu/:menuId/:tableId"
          render={(props: any) => (
            <MenuPage menuId={props.match.params.menuId} tableId={props.match.params.tableId} />
          )}
        />
        <Route path="/orderSuccess/:menuId/:tableId" render={() => <OrderSuccessPage />} />
        <Route path="/:menuId/:tableId/:orderId" render={() => <CheckErrorPayment />} />
        <Route path="/cart" render={() => <CartPage />} />
        <Route path="/order-history" render={() => <OrderHistoryPage />} />
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    </BrowserRouter>
  );
}

export default RootRouter;
