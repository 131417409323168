import { getTimeZoneStorage } from "./localStorage";

export const MESSAGE_ADD_TO_CART_ITEMS_SUCCESS =
  "The items have been added to the cart";
export const MESSAGE_ADD_TO_CART_ITEM_SUCCESS =
  "The item has been added to the cart";

export const defaultTimeZone = "Australia/Sydney";
export const TIME_ZONE = "TIME_ZONE";
export const timeZone = getTimeZoneStorage() || defaultTimeZone;
export const URL_HOME = "URL_HOME";
export const ORDER_BUDDY_STATE = "ORDER_BUDDY_STATE";

export const appConstants = {
  dateFormat: "DD/MM/YYYY",
  dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
  required: ";required",
};

export const ORDER_SUCCESS = "orderSuccess";
export const ZH_CN = "zh-CN";
export const ZH_SG = "zh-SG";
export const ZH_TW = "zh-TW";
export const ZH_HK = "zh-HK";

export const formatYYMMDD = "YYYY/MM/DD";
export const formatDDDD = "dddd";
export const formatDDD = "ddd";
export const formatHHmm = "HH:mm";
export const getMinute = "minute";
export const getHour = "hour";
