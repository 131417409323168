import { EEventAction, EEventCategory } from 'utils/constants';
import { analytics } from './analytics';

const useAnalyticsEventTracker = () => {
  const eventTracker = (category: EEventCategory, action: EEventAction, label: string) => {
    return analytics.track(category, {
      action: action,
      label: label,
    });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
