import { CountryCode } from 'libphonenumber-js';

export const appConstants = {
  dateFormat: 'DD/MM/YYYY',
  dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
  required: ';required',
};

export const timeOrderHistory = 120;

export const MESSAGE_ADD_TO_CART_ITEMS_SUCCESS = 'The items have been added to the cart';
export const MESSAGE_ADD_TO_CART_ITEM_SUCCESS = 'The item has been added to the cart';
export const MESSAGE_MERCHANT_IS_NOW_NOT_OPERATING =
  'The merchant is currently not in operation. Please place your order when they reopen.';
export const MESSAGE_SOMETHING_WENT_WRONG = 'Something went wrong! Please try again.';
export const MESSAGE_CART_EMPTY = 'Your cart is empty';
export const MESSAGE_INVALID_TIME =
  'It is not operating time. Please choose another time to order.';
export const MESSAGE_SERVICE_BELL_REQUEST_SUCCESS = 'The request has been sent successfully';
export const MESSAGE_SERVICE_BELL_REQUEST_ERROR =
  'Sorry, your request could not be completed. Please try again.';
export const PLEASE_PAY_AT_COUNTER_NOW = 'Please pay at counter now';
export const MESSAGE_PAYMENT_ERROR =
  'Your payment has failed. Please use a different card or try again.';
export const MENU_VERSION = 'MENU_VERSION';
export const MENU_LOCAL = 'MENU_LOCAL';
export const MENU_ID = 'MENU_ID';
export const MESSAGE_CHOOSE_SIZE = 'Please choose the size of the item.';

export enum EStatusMessage {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum EFieldName {
  NAME = 'Name',
  NUMBER_OF_GUEST = 'Number of guest',
  PHONE_NUMBER = 'Phone number',
  CODE = 'Code',
  POST_CODE = 'Post code',
  ADDRESS = 'Address',
  TIME = 'Time',
  DATE = 'Date',
}

export const TOAST_TIME_MESSAGE_ADD_TO_CART = 2000;
export const defaultCountry: CountryCode | undefined = 'AU';
export const TYPES = ['online', 'menu', 'step', 'group'];
export const stringInvalid = ['null', 'undefined'];
export const errorNull = 'TableId or menuId is invalid.';
export const invalidCodeRecaptcha = 'invalid code';
export const messageInvalidExecuteRecaptcha = 'Invalid executeRecaptcha';
export const messageInvalidRecaptchaToken = 'Invalid reCaptcha token';

export enum EEventCategory {
  HOME_SCREEN_LOADING = 'Home Screen Loading',
  ITEM_TAP_EVENT = 'Item Tap Event',
  ADD_CART_EVENT = 'Add Cart Event',
  TABLE_SERVICE_EVENT = 'Table Service Event',
  CHECK_ORDER_HISTORY_EVENT = 'Check Order History Event',
  CHECKOUT_EVENT = 'Checkout Event',
  PAYMENT_TYPE_SELECT_EVENT = 'Payment Type Select Event',
  INCREASE_QUANTITY_EVENT = 'Increase Quantity Event',
  DECREASE_QUANTITY_EVENT = 'Decrease Quantity Event',
  OPTION_ADD_EVENT = 'Option Add Event',
  ADS_TAP_EVENT = 'Ads Tap Event',
  SHOPPING_CART_LOADING_EVENTS = 'Shopping Cart Loading Events',
  ERROR_EVENT = 'Error Event',
}

export enum EEventAction {
  LOADING = 'Loading',
  TAP = 'Tap',
  ADD = 'Add',
  CHECK = 'Check',
  SELECT = 'Select',
  CHECKOUT = 'Checkout',
  INCREASE = 'Increase',
  DECREASE = 'Decrease',
  ERROR = 'Error',
  SEND = 'Send',
}

export enum ETypeMenu {
  GROUP = 'group',
  STEP = 'step',
  MENU = 'menu',
  ONLINE = 'online',
}

export const timeoutCode = 'ECONNABORTED';
export const networkError = 'Network Error';

export const retrySettingUseQuery = {
  retry: 3, // retry failed requests up to 3 times

  retryDelay: 1000, // wait 1 second between retries

  staleTime: 600000, // cache data for 60 minutes

  refetchOnWindowFocus: true, // don't refetch when the window regains focus

  suspense: false, // do not use suspense mode

  timeout: 8000, // set a 8 second timeout for requests
};

export const retrySettingUseMutation = {
  retry(failureCount: number, error: any) {
    return failureCount < 3 && (error?.code === timeoutCode || error?.message === networkError);
  },
  retryDelay: 1000,
};

export const handleResetScrollToTop = () => {
  const dialog = document?.getElementById('groupOptionsScroller') || null;
  if (dialog) {
    dialog?.scrollIntoView();
  }
};
