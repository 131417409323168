import API from 'api/api';
import { createStoreCustom } from 'features/home/store/createStore';
import i18n from 'i18n';
import moment from 'moment';
import { toast } from 'react-toastify';
import { renderItemsCart, renderMergeItemCart } from 'submodules/OrderCore';
import { EFetchingStatus } from 'types/globalType';
import { appConstants } from 'utils/constants';
import { IHandleErrorProps, handleError, shortUUID } from 'utils/restaurantFeatures';
import { CartState, EPickupDelivery, IOrderCart, ISendCodeProps } from '../types/CartTypes';
import produce from 'immer';
import { IPlaceOrderProps } from './cartSliceGroup';
import { AxiosResponse } from 'axios';

const initialState: CartState = {
  cart: {
    lastUpdated: new Date(),
    user: {
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      postcode: '',
      foodAllergies: '',
      geolocation: {},
      guestNum: '',
    },
    order: {
      items: [],
      pickupTimeType: 'now',
      pickupTime: '',
      selectedDate: '',
      total: 0,
      paymentType: null,
      isPaymentRequired: false,
      type: '',
      realTableId: '',
      chooseType: EPickupDelivery.PICKUP,
      notes: '',
      orderTime: '',
      orderNumber: '',
    },
    restaurant: {
      restaurantId: '',
      tableId: '',
    },
    menuId: '',
    browser: 'Chrome',
    mobileModel: 'none',
    verifyCode: '',
    sid: '',
    recaptcha: '',
  },
  status: EFetchingStatus.LOADING,
  isDuplicate: false,
  reference: '',
  placeOrderResponse: {
    isTillPayment: false,
    payUrl: '',
    reference: '',
    sessionId: '',
    stripeAccount: '',
  },
  placeOrderResponseAdyen: {
    clientKey: '',
    environment: '',
    isAdyenPayment: false,
    locale: '',
    dateStr: '',
    yearStr: '',
    paymentResult: {
      amount: {
        value: 0,
        currency: '',
      },
      channel: '',
      countryCode: '',
      expiresAt: '',
      id: '',
      merchantAccount: '',
      reference: '',
      returnUrl: '',
      sessionData: '',
      shopperLocale: '',
      splits: [],
    },
    reference: '',
  },
  urlHome: '',
  orderHistory: {
    items: [],
  },
};

export interface IHomeCartStore {
  cartState: CartState;
  placeOrder: (mutated: AxiosResponse<any> | unknown) => void;
  sendCode: ({
    mobilePhone,
    recaptcha,
    restaurantId,
    tableId,
    eventTracker,
  }: ISendCodeProps) => void;
  updateCart: (action) => void;
  updateUserInCart: (action) => void;
  updateOrderInCart: (action) => void;
  updateRestaurantInCart: (action) => void;
  addToCart: (action) => void;
  removeItem: (action) => void;
  increaseItem: (action) => void;
  switchTakeawayItem: (action) => void;
  removePlaceOrderResponse: () => void;
  removePlaceOrderResponseAdyen: () => void;
  setOrderHistory: (order, reference, tableId) => void;
  setStatus: (status: EFetchingStatus) => void;
  handleErrorMutation: (
    err,
    { payload, infoRestaurant, keyStorage, tableId, orderNumber, eventTracker }: IPlaceOrderProps,
  ) => void;
}

export const useCartStore = createStoreCustom<IHomeCartStore>(
  initialState,
  (get, set) => {
    return {
      placeOrder: async (mutated: AxiosResponse<any>) => {
        try {
          const data = mutated?.data;
          const isPaymentAdyen = data?.isAdyenPayment;
          if (isPaymentAdyen) {
            set(
              produce((state: IHomeCartStore) => {
                const responseAdyen = state.cartState.placeOrderResponseAdyen;
                state.cartState.placeOrderResponseAdyen = {
                  ...responseAdyen,
                  ...data,
                  countryCode: data.countryCode,
                  ...(responseAdyen.paymentResult.amount = {
                    currency: data.currency,
                    value: data.orderTotal,
                  }),
                };
              }),
            );
            return;
          }
          if (data?.reference) {
            set(
              produce((state: IHomeCartStore) => {
                state.cartState.reference = data.reference;
                state.cartState.placeOrderResponse = data;
              }),
            );
          }
        } catch (error: any) {}
      },
      sendCode: async ({
        mobilePhone,
        recaptcha,
        restaurantId,
        tableId,
        eventTracker,
      }: ISendCodeProps) => {
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.status = EFetchingStatus.LOADING;
          }),
        );
        try {
          const res = await API.post('/sendCode', {
            mobilePhone,
            recaptcha,
            restaurantId,
          });
          const data = await res?.data;
          if (res && data && data?.sid) {
            set(
              produce((state: IHomeCartStore) => {
                state.cartState.status = EFetchingStatus.SUCCEEDED;
                state.cartState.cart.sid = data;
              }),
            );
          }
        } catch (error: any) {
          set(
            produce((state: IHomeCartStore) => {
              state.cartState.status = EFetchingStatus.FAILED;
              state.cartState.error = error?.message;
            }),
          );
          const errorData: IHandleErrorProps = {
            logErrorData: {
              endpoint: 'sendCode',
              menuId: restaurantId,
              orderNumber: '',
              tableId,
              error,
              fileName: 'cartSlice',
              functionName: 'sendCodeAPI',
            },
            eventTracker,
          };
          handleError(errorData);
          toast.error(i18n.t('Something went wrong! Please try again.'));
        }
      },
      updateCart: (action) => {
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.cart = action;
          }),
        );
      },
      updateUserInCart: (action) => {
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.cart.user = action;
          }),
        );
      },
      updateOrderInCart: (action) => {
        const isEmpty = action.order.items.length === 0;
        if (isEmpty) {
          set(
            produce((state: IHomeCartStore) => {
              state.cartState.isDuplicate = false;
            }),
          );
        }
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.cart.lastUpdated = new Date();
            state.cartState.cart.order = action.order;
            state.cartState.cart.order.orderNumber = isEmpty
              ? ''
              : get().cartState?.cart?.order?.orderNumber;
          }),
        );
      },
      updateRestaurantInCart: (action) => {
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.cart.restaurant = action;
            state.cartState.cart.lastUpdated = new Date();
          }),
        );
      },
      addToCart: (action) => {
        const isEmpty = get().cartState.cart?.order?.items.length === 0;
        const newTotal =
          get().cartState.cart?.order?.total + action.item.subTotal * action.item.quantity;
        const newOrder: IOrderCart = {
          ...get().cartState.cart?.order,
          items: renderItemsCart(get().cartState.cart?.order.items, action.item),
          total: Number(newTotal.toFixed(2)),
          orderNumber: isEmpty ? shortUUID() : get().cartState.cart?.order?.orderNumber,
        };
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.cart = {
              ...get().cartState.cart,
              order: newOrder,
              lastUpdated: new Date(),
            };
          }),
        );
        // toast.success(i18n.t(getMessageAddToCart(action.item.quantity)), {
        //   autoClose: TOAST_TIME_MESSAGE_ADD_TO_CART,
        // });
      },
      removeItem: (action) => {
        const index = action.index;
        let items = [...get().cartState?.cart?.order.items];
        const item = { ...items[index] };
        let newTotal = get().cartState?.cart?.order.total;
        if (item.quantity > 1) {
          newTotal = get().cartState.cart?.order.total - item.subTotal;
          item.quantity--;
          items.splice(index, 1, item);
        } else {
          newTotal = get().cartState.cart?.order.total - item.subTotal * item.quantity;
          items.splice(index, 1);
        }

        const newOrder: IOrderCart = {
          ...get().cartState.cart?.order,
          items,
          total: Number(newTotal.toFixed(2)),
          orderNumber: items.length === 0 ? '' : get().cartState.cart?.order.orderNumber,
        };
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.cart = {
              ...get().cartState.cart,
              order: newOrder,
              lastUpdated: new Date(),
            };
          }),
        );
      },
      increaseItem: (action) => {
        const index = action.index;
        let items = [...get().cartState?.cart?.order.items];
        const item = { ...items[index] };
        let newTotal = get().cartState?.cart?.order.total;
        newTotal = get().cartState.cart?.order.total + item.subTotal;
        item.quantity++;
        items.splice(index, 1, item);

        const newOrder: IOrderCart = {
          ...get().cartState.cart?.order,
          items,
          total: Number(newTotal.toFixed(2)),
        };
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.cart = {
              ...get().cartState.cart,
              order: newOrder,
              lastUpdated: new Date(),
            };
          }),
        );
      },
      switchTakeawayItem: (action) => {
        const index = action.index;
        let items = [...get().cartState?.cart?.order.items];
        const item = { ...items[index] };
        item.isTakeaway = !item.isTakeaway;
        items.splice(index, 1, item);
        const index2 = renderMergeItemCart(items, item, index);
        if (index2 !== false) {
          const item2 = { ...items[index] };
          item.quantity += item2.quantity;
          items.splice(index2, 1);
        }
        const newOrder: IOrderCart = {
          ...get().cartState?.cart?.order,
          items,
        };
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.cart = {
              ...get().cartState.cart,
              order: newOrder,
              lastUpdated: new Date(),
            };
          }),
        );
      },
      removePlaceOrderResponse: () => {
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.isDuplicate = false;
            state.cartState.placeOrderResponse = {
              isTillPayment: false,
              payUrl: '',
              reference: '',
              sessionId: '',
              stripeAccount: '',
            };
          }),
        );
      },
      removePlaceOrderResponseAdyen: () => {
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.placeOrderResponseAdyen = {
              clientKey: '',
              environment: '',
              isAdyenPayment: false,
              locale: '',
              dateStr: '',
              yearStr: '',
              countryCode: '',
              paymentResult: {
                amount: {
                  value: 0,
                  currency: '',
                },
                channel: '',
                countryCode: '',
                expiresAt: '',
                id: '',
                merchantAccount: '',
                reference: '',
                returnUrl: '',
                sessionData: '',
                shopperLocale: '',
                splits: [],
              },
              reference: '',
            };
          }),
        );
      },
      setOrderHistory: (order, reference, tableId) => {
        const state = get().cartState;
        let newOrderHistory = state.orderHistory;
        const selectedDate = newOrderHistory?.items[0]?.order.selectedDate;
        const today = moment().utc().format(appConstants.dateFormat);

        if (today === selectedDate && newOrderHistory) {
          newOrderHistory = {
            items: [{ order, reference, tableId }, ...newOrderHistory.items],
          };
        } else {
          newOrderHistory = {
            items: [{ order, reference, tableId }],
          };
        }
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.orderHistory = newOrderHistory;
            state.cartState.reference = reference;
          }),
        );
      },
      setStatus: (status: EFetchingStatus) => {
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.status = status;
          }),
        );
      },
      handleErrorMutation: (
        error,
        {
          payload,
          infoRestaurant,
          keyStorage,
          tableId,
          orderNumber,
          eventTracker,
        }: IPlaceOrderProps,
      ) => {
        const data = error?.response?.data;
        const errorData: IHandleErrorProps = {
          logErrorData: {
            endpoint: 'placeOrder',
            menuId: infoRestaurant?.menuId,
            orderNumber,
            tableId,
            error,
            fileName: 'cartSlice',
            functionName: 'placeOrderAPI',
            payload: JSON.stringify(payload || ''),
          },
          eventTracker,
        };
        handleError(errorData);
        if (data?.duplicate) {
          set(
            produce((state: IHomeCartStore) => {
              state.cartState.isDuplicate = true;
            }),
          );
          toast.success(i18n.t(data?.message));
          return;
        }
        set(
          produce((state: IHomeCartStore) => {
            state.cartState.status = EFetchingStatus.FAILED;
            state.cartState.error = error?.message;
          }),
        );
        return error.response;
      },
    };
  },
  'cart',
  'cartState',
);
