/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { loadStripe } from '@stripe/stripe-js/pure';
import { detect } from 'detect-browser';
import { useCartStore } from 'features/cart/store/cartSlice';
import { EPickupDelivery, ICart, TPickupTimeType } from 'features/cart/types/CartTypes';
import { useStore } from 'features/home/store/homeSlice';
import {
  SecondaryTextRed,
  SuccessTextDialog,
} from 'features/step-menu/pages/AddInfomation/containers/AddInformationContainerStep';
import { CountryCode } from 'libphonenumber-js';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  checkChooseTimeOpen,
  checkDineMobileOptional,
  disableNumberOfGuest,
  isDelivery,
  isFoodCourt,
  isMobile,
  isPickup,
  isPickupAndDelivery,
  isRenderDatetime,
  isRestaurantOpen,
  isTableNumberAndRequired,
  isTakeaway,
} from 'submodules/OrderCore';
import useAnalyticsEventTracker from 'utils/analytics/useAnalyticsEventTracker';
import {
  EEventAction,
  EEventCategory,
  MESSAGE_CART_EMPTY,
  MESSAGE_INVALID_TIME,
  MESSAGE_MERCHANT_IS_NOW_NOT_OPERATING,
  appConstants,
  defaultCountry,
  retrySettingUseMutation,
} from 'utils/constants';
import {
  getSidStorage,
  getUserInfoStorage,
  setUrlPayment,
  setUserInfoStorage,
} from 'utils/localStorage';
import {
  IHandleErrorProps,
  checkDefaultPaymentMethod,
  checkItemSoldOut,
  checkValidateForm,
  getRecaptchaToken,
  handleError,
  handlePlaceOrder,
  handleRouter,
  handleThrowErrorNull,
  messageErrorForm,
  shortUUID,
  updateUrlHomeStore,
} from 'utils/restaurantFeatures';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';
import { placeOrderAPI } from 'api/apiQueries';
import { HomePageType } from 'features/home/pages/Home/HomePage';
const AddInformation = React.lazy(() => import('../components/AddInformation'));

export interface Information {
  name: string;
  numberOfGuests: string;
  mobile: string;
  email: string;
  postcode: string;
  geolocation: any;
  address: string;
}
export interface IValidates {
  name: boolean;
  numberOfGuests: boolean;
  mobile: boolean;
  pickupTime: boolean;
  selectedDate: boolean;
  address: boolean;
  postcode: boolean;
}

export enum PaymentMethodType {
  WECHAT = 'wechat',
  ALI_PAY = 'alipay',
  BANK_CARD = 'card',
  CASH = 'cash',
  PAY_PAL = 'paypal',
}

function AddInformationContainer() {
  const eventTracker = useAnalyticsEventTracker();
  const history = useHistory();
  const { t } = useTranslation();
  const { menuId, tableId: tableIdParams }: HomePageType = useParams();
  const {
    placeOrderResponse,
    isDuplicate,
    placeOrderResponseAdyen,
    removePlaceOrderResponse,
    sendCode,
    updateOrderInCart,
    updateRestaurantInCart,
    store,
    cart,
  } = useCartStore(
    (state) => ({
      placeOrderResponse: state.cartState.placeOrderResponse,
      isDuplicate: state.cartState.isDuplicate,
      placeOrderResponseAdyen: state.cartState.placeOrderResponseAdyen,
      removePlaceOrderResponse: state.removePlaceOrderResponse,
      sendCode: state.sendCode,
      updateOrderInCart: state.updateOrderInCart,
      updateRestaurantInCart: state.updateRestaurantInCart,
      cart: state.cartState.cart,
      store: state,
    }),
    shallow,
  );
  const { restaurant, tableId, isDineInTable, inactiveMenu, info, memberId, state, setUrlHome } =
    useStore(
      (state) => ({
        restaurant: state.homeState.restaurant,
        tableId: state.homeState.tableId,
        isDineInTable: state.homeState.isDineInTable,
        inactiveMenu: state.homeState.inactiveMenu,
        info: state.homeState.info,
        memberId: state.homeState.memberId,
        state: state.homeState,
        setUrlHome: state.setUrlHome,
      }),
      shallow,
    );
  const { executeRecaptcha } = useGoogleReCaptcha();
  const holidaySurcharge = restaurant?.holidaySurcharge || {};
  const amountSurchargeFee = holidaySurcharge?.amount || 0;
  const userStore = getUserInfoStorage();
  const menuIdOrder = info?.menuId || menuId;
  const tableIdOrder = info?.tableId || tableIdParams;
  const restaurantInfo = {
    menuId: menuIdOrder,
    tableId: tableIdOrder,
  };
  const rederCurentUser = () => {
    if (userStore) {
      return userStore;
    } else {
      return {
        name: '',
        numberOfGuests: '',
        mobile: '',
        email: '',
        postcode: '',
        geolocation: {},
        address: '',
      };
    }
  };
  const [countryCode, setCountryCode] = useState<CountryCode | undefined>(defaultCountry);
  const [information, setInformation] = useState<Information>(rederCurentUser());
  const [validates, setValidates] = useState<IValidates>({
    name: false,
    numberOfGuests: false,
    mobile: false,
    pickupTime: false,
    selectedDate: false,
    address: false,
    postcode: false,
  });
  const [codeVerify, setCodeVerify] = useState({ code: '', status: false });
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(checkDefaultPaymentMethod());
  const [pickupTimeType, setPickupTimeType] = useState<TPickupTimeType>(
    isRenderDatetime(tableId, isDineInTable) && !isRestaurantOpen(restaurant, null, null)
      ? 'chooseTime'
      : 'now',
  );
  const [pickupTime, setPickupTime] = useState(moment().format('HH:mm'));
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [pickupDeliveryType, setPickupDeliveryType] = useState(EPickupDelivery.PICKUP);
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState('');

  useEffect(() => {
    if (navigator.geolocation && navigator.permissions) {
      let geolocation = {};
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition((position) => {
            geolocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              timestamp: position.timestamp,
              accuracy: position.coords.accuracy,
            };
          });
        } else if (result.state === 'denied') {
          geolocation = {
            error: 'User denied Geolocation',
          };
        }
      });
      setInformation({ ...information, geolocation });
    } else {
      console.error('Sorry Location Not available!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToPaymentSuccessPage = () => {
    history.push({
      pathname: `/orderSuccess/${menuIdOrder}/${tableIdOrder}`,
      state: { isPayAtCounter: true, isTakeaway: isTakeaway(tableId) },
    });
  };

  const handleLogError = (error: any, functionName: string) => {
    const errorData: IHandleErrorProps = {
      logErrorData: {
        endpoint: '',
        menuId: menuIdOrder,
        orderNumber: '',
        tableId: tableIdOrder,
        error,
        fileName: 'AddInformationContainer',
        functionName,
      },
      eventTracker,
    };
    handleError(errorData);
  };

  const onRouter = (customUrl: string) => {
    handleRouter({
      history,
      state,
      customUrl,
      fileName: 'AddInformationContainer',
    });
  };

  const getFoodCourtMessage = () => {
    let foodCourtMessage = 'Please pay at counter now';

    const orderCompleteMessages = restaurant.onlineSettings?.orderCompleteMessages ?? [];

    const serverFoodcourtMessage = orderCompleteMessages.find(
      (message) => message.type === 'foodcourt',
    )?.message;
    if (serverFoodcourtMessage && serverFoodcourtMessage !== '') {
      foodCourtMessage = serverFoodcourtMessage;
    }
    return foodCourtMessage;
  };

  useEffect(() => {
    if (placeOrderResponse?.reference && placeOrderResponse?.reference.length > 0) {
      if (placeOrderResponse?.payUrl && placeOrderResponse?.payUrl.trim().length > 0) {
        if (placeOrderResponse?.isTillPayment) {
          onRouter('/payment');
          setUrlPayment(placeOrderResponse.payUrl);
        } else {
          window.location.replace(placeOrderResponse.payUrl);
        }
      } else if (
        placeOrderResponse?.stripeAccount &&
        placeOrderResponse?.stripeAccount.length > 0 &&
        selected === PaymentMethodType.BANK_CARD
      ) {
        handleStripe();
      } else if (!checkRenderPaymentMethod() || selected === PaymentMethodType.CASH) {
        if (isFoodCourt(tableId)) {
          setContentDialog(getFoodCourtMessage());
          setOpenDialog(true);
        } else {
          handleGoToPaymentSuccessPage();
        }
      }
      removePlaceOrderResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeOrderResponse]);

  useEffect(() => {
    if (placeOrderResponseAdyen?.isAdyenPayment) {
      onRouter('/payment-adyen');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeOrderResponseAdyen]);

  const handleStripe = async () => {
    try {
      if (placeOrderResponse.sessionId && placeOrderResponse.stripeAccount) {
        const REACT_APP_STRIPE_KEY = process?.env?.REACT_APP_STRIPE_KEY;
        if (REACT_APP_STRIPE_KEY) {
          const stripe = await loadStripe(REACT_APP_STRIPE_KEY, {
            stripeAccount: placeOrderResponse.stripeAccount,
          });
          if (stripe) {
            const result = await stripe.redirectToCheckout({
              sessionId: placeOrderResponse.sessionId,
            });
            if (result.error) {
              toast.error(`Stripe Payment Error: ${result.error}`);
            }
          }
        }
      }
      removePlaceOrderResponse();
    } catch (error: any) {
      handleLogError(error, 'handleStripe');
    }
  };

  const checkRenderPaymentMethod = () => {
    try {
      if (isPickupAndDelivery(tableId)) {
        return true;
      } else if (isTakeaway(tableId) && restaurant?.takeawayPayment) {
        return true;
      } else if (isPickup(tableId)) {
        return true;
      } else if (isDelivery(tableId)) {
        return true;
      } else if (isTableNumberAndRequired(tableId)) {
        return true;
      } else if (
        isFoodCourt(tableId) &&
        (restaurant?.onlineSettings?.foodCourtPaymentRequired ||
          restaurant?.onlineSettings?.foodCourtPaymentOptional)
      ) {
        return true;
      }
      return false;
    } catch (error: any) {
      handleLogError(error, 'checkRenderPaymentMethod');
      return false;
    }
  };

  const renderPaymentMethodType = (type: PaymentMethodType) => {
    try {
      if (!checkRenderPaymentMethod()) {
        return null;
      } else if (type === PaymentMethodType.CASH) {
        return null;
      } else {
        return type;
      }
    } catch (error: any) {
      handleLogError(error, 'renderPaymentMethodType');
      return null;
    }
  };

  const onSendCode = async () => {
    try {
      if (!isMobile(information.mobile)) {
        setValidates({ ...validates, mobile: true });
      } else {
        const recaptcha = await getRecaptchaToken(executeRecaptcha);
        const payload = {
          mobilePhone: information.mobile,
          recaptcha,
          restaurantId: menuIdOrder,
          tableId: tableIdOrder,
          eventTracker,
        };
        sendCode(payload);
      }
    } catch (error: any) {
      handleLogError(error, 'onSendCode');
    }
  };

  const placeOrderMutation = useMutation(placeOrderAPI, {
    ...retrySettingUseMutation,
  });

  const onSubmit = async () => {
    setLoading(true);
    if (!isRestaurantOpen(restaurant, selectedDate, pickupTime)) {
      toast.error(<span>{t(MESSAGE_MERCHANT_IS_NOW_NOT_OPERATING)}.</span>);
      setLoading(false);
      return;
    }
    const orderNumber =
      checkRenderPaymentMethod() && selected !== PaymentMethodType.CASH
        ? shortUUID()
        : cart?.order?.orderNumber;
    try {
      handleThrowErrorNull(tableIdOrder, menuIdOrder);
      if (!cart?.order?.items?.length) {
        toast.error(t(MESSAGE_CART_EMPTY));
        setLoading(false);
      } else if (
        checkValidateForm(
          information,
          isDineInTable,
          restaurant,
          tableId,
          pickupTimeType,
          pickupTime,
          selectedDate,
          pickupDeliveryType,
          countryCode,
        )
      ) {
        toast.error(
          messageErrorForm(
            information,
            pickupDeliveryType,
            tableId,
            codeVerify,
            isDineInTable,
            restaurant,
            pickupTime,
            selectedDate,
            pickupTimeType,
            countryCode,
          ),
        );
        setLoading(false);
        let validateTemp = { ...validates };

        validateTemp = {
          ...validateTemp,
          name: true,
          mobile: checkDineMobileOptional(
            isDineInTable,
            restaurant.isDineMobileOptional,
            restaurant?.onlineSettings?.isDineInMobilePhoneRequired,
          )
            ? (!isMobile(information.mobile) && information.mobile !== '') ||
              information.mobile !== ''
            : !isMobile(information.mobile),
          pickupTime: true,
          numberOfGuests:
            disableNumberOfGuest(tableId) &&
            checkDineMobileOptional(
              isDineInTable,
              restaurant.isDineMobileOptional,
              restaurant?.onlineSettings?.isDineInGuestNumberRequired,
            )
              ? false
              : !information.numberOfGuests,
          selectedDate: true,
          address: true,
          postcode: true,
        };

        setValidates(validateTemp);
      } else if (restaurant.smsVerify && codeVerify.code === '') {
        setLoading(false);
        setCodeVerify({ ...codeVerify, status: true });
      } else if (
        pickupTimeType === 'chooseTime' &&
        !checkChooseTimeOpen(restaurant, pickupTime, selectedDate)
      ) {
        setLoading(false);
        toast.error(t(MESSAGE_INVALID_TIME));
      } else if (checkItemSoldOut(cart, inactiveMenu)) {
        setLoading(false);
        toast.error(t('Your cart has sold out item. Please check your cart.'));
      } else {
        const recaptcha = await getRecaptchaToken(executeRecaptcha);
        const browser = detect();

        const payload: ICart = {
          ...cart,
          user: {
            ...cart?.user,
            name: information.name,
            email: information.email,
            phoneNumber: information.mobile,
            postcode: information.postcode,
            geolocation: information.geolocation,
            address: information.address,
            guestNum: information.numberOfGuests,
          },
          order: {
            ...cart?.order,
            pickupTimeType,
            orderTime: moment().format(appConstants.dateTimeFormat),
            selectedDate: moment(selectedDate).format(appConstants.dateFormat),
            paymentType: renderPaymentMethodType(selected),
            isPaymentRequired:
              selected === PaymentMethodType.CASH ? false : checkRenderPaymentMethod(),
            pickupTime,
            realTableId: tableId.replace(appConstants.required, ''),
            chooseType: pickupDeliveryType,
            orderNumber: orderNumber,
          },
          restaurant: {
            restaurantId: menuIdOrder,
            tableId: tableIdOrder,
          },
          browser: browser?.name || '',
          menuId: menuIdOrder,
          sid: getSidStorage(),
          recaptcha,
          verifyCode: restaurant?.smsVerify ? codeVerify.code : '',
          cardId: uuidv4(),
        };
        const keyStorage = `_${menuIdOrder}_${tableIdOrder}`;
        updateRestaurantInCart(payload.restaurant);
        updateOrderInCart({ order: { ...payload.order }, info });
        let options = {
          payload,
          infoRestaurant: restaurantInfo,
          keyStorage,
          tableId,
          orderNumber,
          eventTracker,
        };
        if (memberId) {
          options = {
            ...options,
            payload: { ...payload, memberId },
          };
          return;
        }
        await handlePlaceOrder({
          store,
          placeOrderMutation,
          options,
        });
        eventTracker(EEventCategory.CHECKOUT_EVENT, EEventAction.CHECKOUT, orderNumber || '');
        setUserInfoStorage(information);
      }
    } catch (error: any) {
      handleLogError(error, 'onSubmit');
    } finally {
      setLoading(false);
    }
  };

  const onSelectMethod = (type: PaymentMethodType) => {
    setSelected(type);
  };

  const onChangeCode = (event: any) => {
    setCodeVerify({ code: event.target.value, status: false });
  };

  const onChangePhoneNumber = (value: any) => {
    const temp = value ? value : '';
    const newInformation = {
      ...information,
      mobile: temp,
    };
    setInformation(newInformation);
    const validatesTemp = { ...validates, mobile: true };
    setValidates(validatesTemp);
  };

  const onChange = (event: any) => {
    const nameField = event.target.name;
    const value = event.target.value;

    const newInformation = {
      ...information,
      [nameField]: value,
    };
    const validatesTemp = { ...validates, [nameField]: false };
    setValidates(validatesTemp);
    setInformation(newInformation);
  };
  const handleClose = () => {
    history.push({
      pathname: `/orderSuccess/${menuIdOrder}/${tableIdOrder}`,
    });
  };

  useEffect(() => {
    if (isDuplicate) {
      handleGoToPaymentSuccessPage();
      removePlaceOrderResponse();
    }
  }, [isDuplicate]);

  useEffect(() => {
    updateUrlHomeStore({ state, setUrlHome, menuId: menuIdOrder, tableId: tableIdOrder });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js"></script>
      </Helmet>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDialog}>
        <MuiDialogTitle id="customized-dialog-title">
          <SuccessTextDialog> {t('Your order is successful')}!</SuccessTextDialog>
        </MuiDialogTitle>
        <MuiDialogContent>
          <SecondaryTextRed variant="body2">* {contentDialog}!</SecondaryTextRed>
        </MuiDialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <AddInformation
        loading={loading}
        isDineInTable={isDineInTable}
        information={information}
        onSubmit={onSubmit}
        onChange={onChange}
        selected={selected}
        onSelectMethod={onSelectMethod}
        validates={validates}
        restaurant={restaurant}
        amountSurchargeFee={amountSurchargeFee}
        tableId={tableId}
        pickupTimeType={pickupTimeType}
        setPickupTimeType={setPickupTimeType}
        setPickupTime={setPickupTime}
        setSelectedDate={setSelectedDate}
        pickupTime={pickupTime}
        selectedDate={selectedDate}
        pickupDeliveryType={pickupDeliveryType}
        setPickupDeliveryType={setPickupDeliveryType}
        checkRenderPaymentMethod={checkRenderPaymentMethod}
        onSendCode={onSendCode}
        onChangeCode={onChangeCode}
        statusCodeVerify={codeVerify.status}
        codeVerify={codeVerify.code}
        onChangePhoneNumber={onChangePhoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    </Fragment>
  );
}

export default React.memo(AddInformationContainer);
