import i18n from 'i18n';
import produce from 'immer';
import { toast } from 'react-toastify';
import { urlHome } from 'submodules/OrderCore';
import { EFetchingStatus, ELANGUAGE } from 'types/globalType';
import {
  EEventAction,
  EEventCategory,
  MESSAGE_SERVICE_BELL_REQUEST_ERROR,
  MESSAGE_SERVICE_BELL_REQUEST_SUCCESS,
} from 'utils/constants';
import { saveUrlHome, setTimeZoneStorage } from 'utils/localStorage';
import {
  IHandleErrorProps,
  handleError,
  handleThrowErrorNull,
  isNull,
  replaceWhiteSpace,
} from 'utils/restaurantFeatures';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { ICategory, IRestaurant, TTableId } from './../types/homeTypes';
import { MyPersist, dataPersist, renderName } from './createStore';
import { QueryFunction } from 'react-query';
import { getAdsSuccessPageAPI, getMenusQuery, requestServiceBellAPI } from 'api/apiQueries';
import HomeDialog from '../../../components/HomeDialog';

export interface IMenuParams {
  groupId?: string;
  menuId: string;
  tableId: string;
}

export enum EAdsType {
  banner = 'banner',
  carasel = 'carasel',
  grid = 'grid',
  horizontalList = 'horizontalList',
  verticalList = 'verticalList',
}

export interface IAdsResources {
  type: string;
  url: string;
  externalUrl: string;
  language: ELANGUAGE;
}

export interface IAds {
  name: string;
  resources: IAdsResources[];
  sort: number;
  viewType: EAdsType;
}
export interface HomeDialogProps {
  isDialogShown: boolean;
  dialogContent: string;
  dialogTitle: string;
  dialogVersion: string;
}

export interface HomeState {
  categories: ICategory[];
  restaurant: IRestaurant;
  isDineInTable: boolean;
  status: EFetchingStatus;
  error?: string;
  info: IMenuParams;
  tableId: TTableId;
  inactiveMenu: any;
  isOrderingDisabled: boolean;
  dialogSettings: HomeDialogProps;
  ads: IAds[];
  menuIdCategories: string;
  menuVersion: string;
  memberId: string;
  urlHome: string;
  categoryTabId: string;
}

const initialState: HomeState = {
  isDineInTable: false,
  categories: [],
  inactiveMenu: [],
  isOrderingDisabled: false,
  status: EFetchingStatus.LOADING,
  info: {
    menuId: '',
    tableId: '',
  },
  tableId: 'takeaway',
  restaurant: {
    orderAheadDisabled: false,
    isDineMobileOptional: false,
    onlineSettings: {},
    holidaySurcharge: {
      amount: 0,
      isEnabled: false,
      isPercentage: false,
      name: '',
    },
    holidaySurchargeSettings: {
      amount: 0,
      isEnabled: false,
      isPercentage: false,
      publicHolidayDays: [],
      weekdays: [],
      name: '',
    },
    logo: '',
    name: '',
    altName: '',
    address: '',
    phone: '',
    isBigMenuImageUsed: false,
    openHours: {
      closeDaysItems: [],
      openHoursItems: [],
    },
    serviceBell: false,
    serviceTypes: [],
    smsVerify: false,
    takeawayPayment: false,
    themeColor: '',
    urlIdentifier: '',
    timezone: '',
    restaurantId: '',
    currencySymbol: '',
  },
  ads: [],
  menuIdCategories: '',
  menuVersion: '',
  memberId: '',
  urlHome: '',
  categoryTabId: '',
  dialogSettings: {
    isDialogShown: false,
    dialogContent: '',
    dialogTitle: '',
  },
};

export interface IServiceBell {
  menuId: string;
  tableId: string;
  serviceType: string;
  eventTracker: (category: EEventCategory, action: EEventAction, label: string) => Promise<any>;
}

export interface IOnlineMenuProps {
  menuId: string;
  tableId: string;
  eventTracker: (categories: EEventCategory, action: EEventAction, label: string) => Promise<any>;
  groupId?: string;
}

export interface IGetAdsSuccessPage {
  menuId: string;
  tableId: string;
  eventTracker: (category: EEventCategory, action: EEventAction, label: string) => Promise<any>;
}

export interface IHomeStore {
  homeState: HomeState;
  requestServiceBell: ({ menuId, tableId, serviceType }: IServiceBell) => void;
  getAdsSuccessPage: ({ menuId, tableId, eventTracker }: IGetAdsSuccessPage) => void;
  updateInactiveHome: (action) => void;
  checkOrderingDisabledFunc: (dialogSettings: HomeDialogProps) => void;
  checkDialogFunc: (dialogSettings: HomeDialogProps) => void;
  saveInfo: (info) => void;
  setUrlHome: (urlHome: string, menuId: string, tableId: string) => void;
  setMemberId: (memberId: string) => void;
  getMenuQuery: (
    menuId: string,
    tableId: string,
    eventTracker: (category: EEventCategory, action: EEventAction, label: string) => Promise<any>,
  ) => QueryFunction<unknown, 'getMenu'>;
  activeCategoryTab: (tabId: string) => void;
}

export const useStore = create<IHomeStore>(
  (persist as MyPersist<IHomeStore | any>)(
    (set, get) => ({
      homeState: initialState,
      getMenuQuery: async (
        menuId: string,
        tableId: string,
        eventTracker: (
          category: EEventCategory,
          action: EEventAction,
          label: string,
        ) => Promise<any>,
      ) => {
        try {
          handleThrowErrorNull(menuId, tableId);
          const params = {
            menuId,
            tableId,
          };
          const menuVersion = get().homeState.menuVersion;
          set(
            produce((state: IHomeStore) => {
              state.homeState.status = EFetchingStatus.LOADING;
            }),
          );
          const response = await getMenusQuery(menuId, menuVersion, params);
          const saveInfo = get().saveInfo;
          saveInfo({ menuId, tableId });
          const data = await response?.data;
          const checkSameVersion = data?.isSameVersion;
          let menu: ICategory[] = [];
          if (checkSameVersion) {
            const menuLocal = get().homeState.categories;
            menu = menuLocal;
          } else {
            menu = data?.menu?.categories;
          }
          const menuSort = menu?.sort(
            (item1: ICategory, item2: ICategory) => item1.sort - item2.sort,
          );
          set(
            produce((state: IHomeStore) => {
              state.homeState.status = EFetchingStatus.SUCCEEDED;
              state.homeState.categories = menuSort;
              state.homeState.isDineInTable = data.isDineInTable;
              state.homeState.restaurant = data.restaurant;
              state.homeState.tableId = data.tableId;
              state.homeState.menuVersion = data.version;
              state.homeState.menuIdCategories = data.menuId;
            }),
          );
          setTimeZoneStorage(data.restaurant.timezone);
        } catch (error: any) {
          set(
            produce((state: IHomeStore) => {
              state.homeState.status = EFetchingStatus.FAILED;
              state.homeState.error = error?.message;
            }),
          );
          const errorData: IHandleErrorProps = {
            logErrorData: {
              endpoint: 'getMenuQuery',
              menuId,
              orderNumber: '',
              tableId,
              error,
              fileName: 'homeSlice',
              functionName: 'getMenuQueryAPI',
            },
            eventTracker,
          };
          handleError(errorData);
        }
      },
      updateInactiveHome: (action) => {
        set(
          produce((state: IHomeStore) => {
            state.homeState.inactiveMenu = action;
          }),
        );
      },
      checkOrderingDisabledFunc: (action) => {
        set(
          produce((state: IHomeStore) => {
            state.homeState.isOrderingDisabled = action;
          }),
        );
      },
      checkDialogFunc: (dialogSettings: HomeDialogProps) => {
        set(
          produce((state: IHomeStore) => {
            state.homeState.dialogSettings = dialogSettings;
          }),
        );
      },
      requestServiceBell: async ({ menuId, tableId, serviceType, eventTracker }: IServiceBell) => {
        set(
          produce((state: IHomeStore) => {
            state.homeState.status = EFetchingStatus.LOADING;
          }),
        );
        try {
          handleThrowErrorNull(tableId, menuId);
          await requestServiceBellAPI(menuId, tableId, serviceType);
          set(
            produce((state: IHomeStore) => {
              state.homeState.status = EFetchingStatus.SUCCEEDED;
            }),
          );
          toast.success(i18n.t(MESSAGE_SERVICE_BELL_REQUEST_SUCCESS));
        } catch (error: any) {
          set(
            produce((state: IHomeStore) => {
              state.homeState.status = EFetchingStatus.FAILED;
              state.homeState.error = error?.message;
            }),
          );
          const errorData: IHandleErrorProps = {
            logErrorData: {
              endpoint: 'newMessage',
              menuId,
              orderNumber: '',
              tableId,
              error,
              fileName: 'homeSlice',
              functionName: 'requestServiceBellAPI',
            },
            eventTracker,
          };
          handleError(errorData);
          toast.error(i18n.t(MESSAGE_SERVICE_BELL_REQUEST_ERROR));
        }
      },
      getAdsSuccessPage: async ({ menuId, tableId, eventTracker }: IGetAdsSuccessPage) => {
        try {
          handleThrowErrorNull(menuId, tableId);
          const res = await getAdsSuccessPageAPI();
          const data = await res.data;
          set(
            produce((state: IHomeStore) => {
              state.homeState.status = EFetchingStatus.SUCCEEDED;
              state.homeState.ads = data?.advertisements;
            }),
          );
        } catch (error: any) {
          set(
            produce((state: IHomeStore) => {
              state.homeState.status = EFetchingStatus.FAILED;
              state.homeState.error = error?.message;
            }),
          );
          const errorData: IHandleErrorProps = {
            logErrorData: {
              endpoint: 'getAdsSuccessPage',
              menuId,
              orderNumber: '',
              tableId,
              error,
              fileName: 'homeSlice',
              functionName: 'getAdsSuccessPageAPI',
            },
            eventTracker,
          };
          handleError(errorData);
        }
      },
      saveInfo: (info) => {
        set(
          produce((state: IHomeStore) => {
            state.homeState.info = info;
          }),
        );
      },
      setUrlHome: (url: string, menuId: string, tableId: string) => {
        if (isNull([menuId, tableId])) return;
        saveUrlHome(urlHome(replaceWhiteSpace(url)));
        set(
          produce((state: IHomeStore) => {
            state.homeState.urlHome = urlHome(replaceWhiteSpace(url));
          }),
        );
      },
      setMemberId: (memberId: string) => {
        set(
          produce((state: IHomeStore) => {
            state.homeState.memberId = memberId;
          }),
        );
      },
      activeCategoryTab: (tabId: string) => {
        set(
          produce((state: IHomeStore) => {
            state.homeState.categoryTabId = tabId;
          }),
        );
      },
    }),
    {
      name: renderName('home'), // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      partialize: (state) => {
        const result = dataPersist(state.homeState);
        return {
          homeState: result,
        };
      },
    },
  ),
);
