import React from 'react';
import ContentLoader from 'react-content-loader';
import { userDarkMode } from 'theme';

const ReactContentLoader = () => {
  const backgroundColor = userDarkMode ? '#252525' : '#ededed';
  const foregroundColor = userDarkMode ? '#3b3b3b' : '#d6d6d6';
  return (
    <div>
      <FadingLoaderCardHeader backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardSearch backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardRecommendation
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      />
      <FadingLoaderCardCategoryName
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      <FadingLoaderCardItem backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
    </div>
  );
};
export default ReactContentLoader;
const FadingLoaderCardHeader = ({ backgroundColor, foregroundColor }) => {
  return (
    <ContentLoader
      width={'100%'}
      height="55"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="71" y="17" rx="0" ry="0" width="186" height="33" />
      <rect x="10" y="15" rx="4" ry="4" width="40" height="40" />
    </ContentLoader>
  );
};
const FadingLoaderCardSearch = ({ backgroundColor, foregroundColor }) => {
  return (
    <ContentLoader
      width={'100%'}
      height="50"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="10" y="16" rx="0" ry="0" width="100%" height="35" />
    </ContentLoader>
  );
};
const FadingLoaderCardRecommendation = ({ backgroundColor, foregroundColor }) => {
  return (
    <ContentLoader
      width={'100%'}
      height="200px"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="158" y="596" rx="0" ry="0" width="5" height="3" />
      <rect x="9" y="16" rx="0" ry="0" width="304" height="35" />
      <rect x="7" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="140" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="270" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="530" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="660" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="790" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="920" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="1050" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="1180" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="1310" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="1440" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="1570" y="78" rx="0" ry="0" width="110" height="159" />
      <rect x="400" y="78" rx="0" ry="0" width="110" height="159" />
    </ContentLoader>
  );
};
const FadingLoaderCardCategoryName = ({ backgroundColor, foregroundColor }) => {
  return (
    <ContentLoader
      width={'100%'}
      height="50"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="158" y="596" rx="0" ry="0" width="5" height="3" />
      <rect x="11" y="16" rx="0" ry="0" width="217" height="35" />
      <rect x="738" y="250" rx="0" ry="0" width="110" height="159" />
    </ContentLoader>
  );
};
const FadingLoaderCardItem = ({ backgroundColor, foregroundColor }) => {
  return (
    <ContentLoader
      width={'100%'}
      height="115px"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="15" y="30" rx="0" ry="0" width="79" height="79" />
      <rect x="118" y="34" rx="0" ry="0" width="100%" height="21" />
      <rect x="119" y="65" rx="0" ry="0" width="75%" height="16" />
      <rect x="120" y="94" rx="0" ry="0" width="50" height="15" />
    </ContentLoader>
  );
};
