import React from 'react';
import styled from 'styled-components';
import notFound from '../../assets/images/notfaund.png';
const Box = styled.div`
  margin: 0 -20px;
`;
const NotFound = () => (
  <Box className="not-found">
    <img src={notFound} alt="not-found" width="100%" />
  </Box>
);

export default NotFound;
