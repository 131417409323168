import { ORDER_BUDDY_STATE, ORDER_SUCCESS } from "../constants";
import { loadUrlHome, saveUrlHome } from "../localStorage";

export const urlHome = (pathname: string) => {
  return `/${pathname.split("/")[1]}/${pathname.split("/")[2]}/${
    pathname.split("/")[3]
  }`;
};

export const updateUrlHome = (pathname: string) => {
  saveUrlHome(urlHome(pathname));
};

export const renderKey = () => {
  const pathname =
    typeof window !== "undefined" ? window.location.pathname : "";
  const key = setKey(pathname, loadUrlHome());
  return key;
};

export const setKey = (pathname: string, loadUrlHome: string) => {
  const temp = pathname.split("/")[1];
  if (temp !== ORDER_SUCCESS) {
    const key = `${ORDER_BUDDY_STATE}${
      urlHome(pathname).includes("undefine") ? loadUrlHome : urlHome(pathname)
    }`;
    return key;
  } else {
    return `${ORDER_BUDDY_STATE}${loadUrlHome}`;
  }
};

export const getParam = (search: string, name: string) => {
  const params = new URLSearchParams(search);
  const paramOfName = params.get(name) || "";
  return paramOfName;
};
