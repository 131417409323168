import { ICart, IPlaceOrderResponseAdyen } from './../features/cart/types/CartTypes';
import { IMenuParams } from './../features/home/store/homeSlice';
import { IOrderCart } from 'features/cart/types/CartTypes';
import { Information } from 'features/payment/containers/AddInformationContainer';
import moment from 'moment';
import { appConstants, MENU_ID, MENU_LOCAL, MENU_VERSION } from './constants';
import { CartItem } from 'features/cart/store/cartSliceGroup';
import { ICategory } from 'features/home/types/homeTypes';
import { getKeyRestaurant } from './restaurantFeatures';

export const ORDER_HISTORY = 'ORDER_HISTORY';
const ORDER_IN_CART = 'ORDER_IN_CART';
export const ORDER_BUDDY_STATE = 'ORDER_BUDDY_STATE';
export const INFO_RESTAURANT = 'INFO_RESTAURANT';
export const SID = 'SID';
const USER_STATE = 'USER_STATE';
const URL_HOME = 'URL_HOME';
const TIME_ZONE = 'TIME_ZONE';
const ORDER_BUDDY_KEY = 'ORDER_BUDDY_KEY';
const defaultTimeZone = 'Australia/Sydney';

const setStateStorage = (data: any, key: string) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    localStorage.clear();
    localStorage.setItem(key, JSON.stringify(data));
    setTimeZoneStorage(defaultTimeZone);
  }
};

const getStateStorage = (key: string) => {
  const data = localStorage.getItem(key);
  if (data) {
    const dataParsed = JSON.parse(data);
    return dataParsed;
  }
  return null;
};
export const setStateSession = (data: any, key: string) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    sessionStorage.clear();
    sessionStorage.setItem(key, JSON.stringify(data));
    setTimeZoneStorage(defaultTimeZone);
  }
};

export const getStateSession = (key: string) => {
  const data = sessionStorage.getItem(key);
  if (data) {
    const dataParsed = JSON.parse(data);
    return dataParsed;
  }
  return null;
};

export const removeStateStorage = (key: string) => localStorage.removeItem(key);

// ROOT STATE
export const saveRootState = (state: any, key: string) => setStateStorage(state, key);
export const loadRootState = (key: string) => getStateStorage(key);

export const saveUrlHome = (url: string) => setStateStorage(url, URL_HOME);
export const loadUrlHome = () => getStateStorage(URL_HOME);

// ROOT KEY
export const saveRootKeys = (state: any[]) => setStateStorage(state, ORDER_BUDDY_KEY);
export const loadRootKeys = (): any[] => getStateStorage(ORDER_BUDDY_KEY);

//INFO RESTAURANT
export const setInfoRestaurantStorage = (info: IMenuParams) =>
  setStateStorage(info, INFO_RESTAURANT);
export const getInfoRestaurantStorage = () => getStateStorage(INFO_RESTAURANT);

// CART
export const setOrderInCartUserStorage = (cart: ICart | CartItem[], info: IMenuParams) =>
  setStateStorage(cart, `ORDER_IN_CART${loadUrlHome()}`);
export const getOrderInCartUserStorage = (key: string) => getStateStorage(key);

//info user
export const setUserInfoStorage = (info: Information) => setStateStorage(info, USER_STATE);
export const getUserInfoStorage = () => getStateStorage(USER_STATE);

//sid
export const setSidStorage = (sid: string) => setStateStorage(sid, SID);
export const getSidStorage = () => getStateStorage(SID);

//time zone
export const setTimeZoneStorage = (timeZone: string) => setStateStorage(timeZone, `${TIME_ZONE}`);
export const getTimeZoneStorage = () => getStateStorage(`${TIME_ZONE}`);

// ORDER HISTORY
export const setOrderHistoryStorage = (order: IOrderCart, reference: string, tableId: string) => {
  const keyRestaurant = getKeyRestaurant(loadUrlHome());
  const key = `${ORDER_HISTORY}${keyRestaurant}`;
  let newOrderHistory = getOrderHistoryStorage(keyRestaurant);
  const selectedDate = newOrderHistory?.items[0]?.order.selectedDate;
  const today = moment().utc().format(appConstants.dateFormat);

  if (today === selectedDate && newOrderHistory) {
    newOrderHistory = {
      items: [{ order, reference, tableId }, ...newOrderHistory.items],
    };
  } else {
    newOrderHistory = {
      items: [{ order, reference, tableId }],
    };
  }
  setStateStorage(newOrderHistory, key);
};
export const getOrderHistoryStorage = (keyRestaurant: string) =>
  getStateStorage(`${ORDER_HISTORY}${keyRestaurant}`);

//urlPayment
export const setUrlPayment = (url: string) => setStateStorage(url, 'URL_PAYMENT');
export const getUrlPayment = () => getStateStorage('URL_PAYMENT');

//memberId
export const setMemberId = (id: string, type: string) =>
  setStateStorage(id, `MEMBERID_${type?.toUpperCase()}`);
export const getMemberId = (type: string) => getStateStorage(`MEMBERID_${type?.toUpperCase()}`);

export const setUrlFirstHome = (id: string) => setStateStorage(id, 'URL_FIRST_HOME');
export const getUrlFirstHome = () => getStateStorage('URL_FIRST_HOME');

export const setOrderState = (newState: any) =>
  setStateStorage(newState, `ORDER_BUDDY_STATE${loadUrlHome()}`);
export const getOrderState = () => getStateStorage(`ORDER_BUDDY_STATE${loadUrlHome()}`);

//menu version
export const setMenuVersion = (menuVersion: string) =>
  setStateStorage(menuVersion, `${MENU_VERSION}${loadUrlHome()}`);
export const getMenuVersion = () => getStateStorage(`${MENU_VERSION}${loadUrlHome()}`);

//menuId
export const setMenuId = (menuId: string) => setStateStorage(menuId, `${MENU_ID}${loadUrlHome()}`);
export const getMenuId = () => getStateStorage(`${MENU_ID}${loadUrlHome()}`);

//menu local
export const setMenuLocal = (menuLocal: ICategory[]) =>
  setStateStorage(menuLocal, `${MENU_LOCAL}${loadUrlHome()}`);
export const getMenuLocal = () => getStateStorage(`${MENU_LOCAL}${loadUrlHome()}`);

export const setAdyenPayment = (data: IPlaceOrderResponseAdyen[]) =>
  setStateStorage(data, `ADYEN_PAYMENT${loadUrlHome}`);
export const getAdyenPayment = () => getStateStorage(`ADYEN_PAYMENT${loadUrlHome}`);
