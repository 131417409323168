import moment from "moment-timezone";
import {
  appConstants,
  defaultTimeZone,
  formatDDD,
  formatDDDD,
  formatHHmm,
  formatYYMMDD,
  getHour,
  getMinute,
} from "../constants";
import { getTimeZoneStorage } from "../localStorage";
import {
  IRestaurant,
  ISizeSelect,
  PaymentMethodType,
  TTableId,
} from "../types";
import { isValidPhoneNumber } from "libphonenumber-js";

export const getCurrentDate = () => {
  let newDate = new Date();

  return moment(newDate).format(formatYYMMDD);
};

export const isRestaurantClosed = (restaurant: IRestaurant) => {
  const openHoursItems = restaurant?.openHours?.openHoursItems || [];
  const openHoursItem = openHoursItems[0] || null;
  if (!openHoursItem) {
    return true;
  }
  isRestaurantOpen(restaurant, null, null);

  const openEnd = openHoursItem.openEnd;
  const openStart = openHoursItem.openStart;
  const weekdays: any[] = openHoursItem.weekdays;
  const todayInWeekDays = moment().format(formatDDDD);
  const dateTimeClose = moment(
    new Date(
      `${moment()
        .tz(restaurant?.timezone || defaultTimeZone)
        .format(formatYYMMDD)} ${openEnd}`
    )
  );
  const dateTimeOpen = moment(
    new Date(
      `${moment()
        .tz(restaurant?.timezone || defaultTimeZone)
        .format(formatYYMMDD)} ${openStart}`
    )
  );
  if (
    restaurant?.openHours?.closeDaysItems &&
    restaurant.openHours.closeDaysItems.length === 0
  ) {
    return false;
  }
  if (
    weekdays.includes(todayInWeekDays) &&
    moment().isBetween(dateTimeOpen, dateTimeClose)
  ) {
    return false;
  }
  return true;
};



export const checkTimeOpen = (
  timeNow: string,
  timeOpen: string,
  timeClose: string
) => {
  return (
    moment(timeNow, formatHHmm).diff(moment(timeOpen, formatHHmm)) >= 0 &&
    moment(timeClose, formatHHmm).diff(moment(timeNow, formatHHmm)) > 0
  );
};


export const parseSeletedDateTime = (dateSelected: string, timeSelected: string) => {
  const newDate = new Date(dateSelected);
  const timeZone = getTimeZoneStorage() || defaultTimeZone;
  const daySelected = moment(newDate).tz(timeZone).format(formatDDDD);
  const date = moment(newDate).tz(timeZone).format(appConstants.dateFormat);
  const time = moment(timeSelected, formatHHmm).tz(timeZone).format(formatHHmm);
  return { date, daySelected, time };
};

export const isRestaurantOpen = (restaurant: IRestaurant, dateSelected: string | null = null, timeSelected: string | null = null) => {
  const timeZone = getTimeZoneStorage() || defaultTimeZone;
  let selectedDate = moment().tz(timeZone)?.format(appConstants.dateFormat);
  let selectedTime = moment().tz(timeZone)?.format(formatHHmm);
  let checkDate = moment().tz(timeZone)?.format(formatDDDD);

  if (dateSelected && timeSelected) { 
    const { date, daySelected, time } = parseSeletedDateTime(dateSelected, timeSelected);
    selectedDate = date;
    selectedTime = time;
    checkDate = daySelected;
  }

  const openHoursItems = restaurant?.openHours?.openHoursItems || [];
  const closeDaysItems = restaurant?.openHours?.closeDaysItems || [];
  let open = false;
  if (openHoursItems.length === 0 && closeDaysItems.length === 0) {
    return true;
  }
  for (let item of closeDaysItems) {
    if (selectedDate === item) {
      return false;
    } else {
      open = true;
    }
  }

  for (let item of openHoursItems) {
    const isOpenDay = item.weekdays.some((day: any) => day === checkDate);
    const isHourOpen = checkTimeOpen(selectedTime, item.openStart, item.openEnd);
    if (isOpenDay && isHourOpen) {
      return true;
    } else {
      open = false;
    }
  }

  return open;
};



export const checkChooseTimeOpen = (
  restaurant: IRestaurant,
  timeSelected: string,
  dateSelected: string
) => {
  const { date, daySelected, time } = parseSeletedDateTime(dateSelected, timeSelected);
  const openHoursItems = restaurant?.openHours?.openHoursItems || [];
  const closeDaysItems = restaurant?.openHours?.closeDaysItems || [];
  let open = false;
  if (openHoursItems.length === 0 && closeDaysItems.length === 0) {
    return true;
  }
  for (let item of closeDaysItems) {
    if (date === item) {
      return false;
    } else {
      open = true;
    }
  }

  for (let item of openHoursItems) {
    const isOpenDay = item.weekdays.some((day: any) => day === daySelected);
    const isHourOpen = checkTimeOpen(time, item.openStart, item.openEnd);
    if (isOpenDay && isHourOpen) {
      return true;
    } else {
      open = false;
    }
  }

  return open;
};

export const isHolidaySurcharge = (restaurant: IRestaurant) => {
  const holidaySurcharge = restaurant?.holidaySurcharge;
  const timeZone = getTimeZoneStorage() || defaultTimeZone;
  if (holidaySurcharge?.isEnabled) {
    const holidaySurchargeSettings = restaurant?.holidaySurchargeSettings;
    const publicHolidayDays = holidaySurchargeSettings?.publicHolidayDays || [];
    const weekdays = holidaySurchargeSettings?.weekdays || [];
    const today = moment().tz(timeZone).format(appConstants.dateFormat);
    const dayInWeek = moment().tz(timeZone).format(formatDDDD);

    if (publicHolidayDays.includes(today) || weekdays.includes(dayInWeek)) {
      return true;
    }
  }
  return false;
};

export const checkDateIsHolidaySurcharge = (
  restaurant: IRestaurant,
  dateSelected: string
) => {
  const newDate = new Date(dateSelected);
  const holidaySurcharge = restaurant?.holidaySurcharge;
  const timeZone = getTimeZoneStorage() || defaultTimeZone;
  if (holidaySurcharge?.isEnabled) {
    const holidaySurchargeSettings = restaurant?.holidaySurchargeSettings;
    const publicHolidayDays = holidaySurchargeSettings?.publicHolidayDays || [];
    const weekdays = holidaySurchargeSettings?.weekdays || [];
    const today = moment(newDate).tz(timeZone).format(appConstants.dateFormat);
    const dayInWeek = moment(newDate).tz(timeZone).format(formatDDDD);
    if (publicHolidayDays.includes(today) || weekdays.includes(dayInWeek)) {
      return true;
    }
  }
  return false;
};

export const checkDayIsInWeekDays = (day: any, listDay: any) => {
  return listDay.includes(day);
};

export const filterVisibility = (menuObj: any) => {
  const timeZone = getTimeZoneStorage() || defaultTimeZone;
  const now = moment().tz(timeZone);
  const minutes = now.get(getMinute) + now.get(getHour) * 60;
  const day = now.format(formatDDD);
  if (!menuObj.active) {
    return false;
  }
  if (menuObj.hideForWeb) {
    return false;
  }

  if (menuObj.active && menuObj.hidden_until) {
    const hiddenUtilDate = moment(menuObj.hidden_until);
    if (now < hiddenUtilDate) {
      return false;
    }
  }

  if (menuObj.active && menuObj.hideDays && menuObj.hideDays?.length > 0) {
    if (menuObj.hideDays.includes(day)) {
      return false;
    }
  }
  if (
    menuObj.active &&
    menuObj.activeWeekDays &&
    menuObj.activeWeekDays?.length > 0
  ) {
    if (!menuObj.activeWeekDays.includes(day)) {
      return false;
    }
    if (menuObj.active && (menuObj.active_begin || menuObj.active_end)) {
      if (menuObj.active_begin < 1440 && menuObj.active_end <= 1440) {
        if (minutes < menuObj.active_begin || minutes > menuObj.active_end) {
          return false;
        }
      }
      if (menuObj.active_begin < 1440 && menuObj.active_end >= 1440) {
        if (menuObj.active_end - 1440 < menuObj.active_begin) {
          if (
            minutes < menuObj.active_begin &&
            minutes > menuObj.active_end - 1440
          ) {
            return false;
          }
        }
      }
    }
  }
  if (
    menuObj.active &&
    !menuObj.activeWeekDays &&
    menuObj.active_begin >= 0 &&
    menuObj.active_end
  ) {
    if (menuObj.active_begin < 1440 && menuObj.active_end <= 1440) {
      if (minutes < menuObj.active_begin || minutes > menuObj.active_end) {
        return false;
      }
    }
    if (menuObj.active_begin < 1440 && menuObj.active_end >= 1440) {
      if (menuObj.active_end - 1440 < menuObj.active_begin) {
        if (
          minutes < menuObj.active_begin &&
          minutes > menuObj.active_end - 1440
        ) {
          return false;
        }
      }
    }
    if (menuObj.active_begin >= 1440 && menuObj.active_end > 1440) {
      if (
        minutes <= menuObj.active_begin - 1440 ||
        minutes > menuObj.active_end - 1440
      ) {
        return false;
      }
    }
  }
  return true;
};

export const isRenderDatetime = (tableId: TTableId, isDineInTable: boolean) => {
  if (isFoodCourt(tableId)) {
    return false;
  } else if (isTableNumberAndRequired(tableId)) {
    return false;
  } else if (isTableNumber(tableId)) {
    return false;
  } else if (isTableDine(isDineInTable)) {
    return false;
  }
  return true;
};

export const isTakeaway = (tableId: TTableId) => {
  return tableId === "takeaway";
};

export const isPickupAndDelivery = (tableId: TTableId) => {
  return tableId === "pickup-delivery";
};

export const isPickup = (tableId: TTableId) => {
  return tableId === "pickup";
};

export const isDelivery = (tableId: TTableId) => {
  return tableId === "delivery";
};

export const isFoodCourt = (tableId: TTableId) => {
  return tableId === "foodcourt";
};

export const disableNumberOfGuest = (tableId: TTableId) => {
  return (
    isTakeaway(tableId) ||
    isPickup(tableId) ||
    isFoodCourt(tableId) ||
    isDelivery(tableId) ||
    isPickupAndDelivery(tableId)
  );
};

export const showSwitchTakeaway = (
  tableId: any,
  restaurant: IRestaurant,
  isTakeawayDisabled: boolean,
  isDineInTable?: boolean,
) => {
  // console.log("showSwitchTakeaway",  restaurant.onlineSettings?.dineinTakeawayEnabled, isTakeawayDisabled, isDineInTable);
  return (
    ((isFoodCourt(tableId) &&
      restaurant.onlineSettings?.foodCourtTakeawayEnabled) ||
    (isTableDine(tableId) &&
        restaurant.onlineSettings?.dineinTakeawayEnabled)) &&
    !isTakeawayDisabled && !isPickup(tableId) && !isTakeaway(tableId)
  );
};

export const showIsNotTakeaway = (
  tableId: any,
  restaurant: IRestaurant,
  isTakeawayDisabled: boolean
) => {
  return (
    ((isFoodCourt(tableId) &&
      restaurant.onlineSettings?.foodCourtTakeawayEnabled) ||
      (isTableNumberAndRequired(tableId) &&
        restaurant.onlineSettings?.dineinTakeawayEnabled) ||
      (isTableDine(tableId) &&
        restaurant.onlineSettings?.dineinTakeawayEnabled)) &&
    isTakeawayDisabled
  );
};

export const isTableNumber = (tableId: string) => {
  const name = (tableId?.charAt(0).toUpperCase() + tableId?.slice(1)).replace(
    appConstants.required,
    ""
  );
  return !isNaN(+name);
};

export const isTableDine = (isDineInTable: boolean) => {
  return isDineInTable;
};

export const getRestaurantId = (restaurantId: string) => {
  return restaurantId.split("_")[0];
};

export const checkDineMobileOptional = (
  isDineInTable: boolean,
  isDineMobileOptional: boolean,
  isRequired: boolean
) => {
  return (
    isTableDine(isDineInTable) &&
    ((isDineMobileOptional && isRequired) || !isRequired)
  );
};

export const isMobile = (mobile: string) => {
  return isValidPhoneNumber(mobile);
};
export const isTableNumberAndRequired = (tableId: string) => {
  return tableId?.includes(appConstants.required);
};

export const decodeRestaurantId = (menuId: any) => {
  if (!menuId) {
    return null;
  }
  let restaurantId = menuId;
  if (menuId.includes("_")) {
    restaurantId = menuId.split("_")[0];
  }
  return restaurantId;
};

export const inactiveMenuIds = (inactiveMenu: any[]) => {
  const Ids =
    inactiveMenu?.length > 0 &&
    typeof inactiveMenu[0] === "object" &&
    inactiveMenu[0] !== null
      ? inactiveMenu
          ?.filter(
            (item: any) =>
              item?.effectiveDate === "" ||
              moment(item?.effectiveDate).format(formatYYMMDD) ===
                getCurrentDate()
          )
          .map((item: any) => {
            return item?.menuItemId;
          })
      : inactiveMenu;
  return Ids;
};

export const renderTableId = (tableId: string, isDineInTable: boolean) => {
  const name = (tableId.charAt(0).toUpperCase() + tableId.slice(1))
    .replace(appConstants.required, "")
    .replace("-", " ");
  if (
    !isNaN(+name) ||
    isTableNumberAndRequired(tableId) ||
    isTableDine(isDineInTable)
  ) {
    return `Table  ${name}`;
  }
  return name;
};

export const checkPlaceOrderResponse = (props: any) => {
  const checkRenderPaymentMethod = () => {
    if (isPickupAndDelivery(props.tableId)) {
      return true;
    } else if (isTakeaway(props.tableId) && props.restaurant.takeawayPayment) {
      return true;
    } else if (isPickup(props.tableId)) {
      return true;
    } else if (isDelivery(props.tableId)) {
      return true;
    } else if (isTableNumberAndRequired(props.tableId)) {
      return true;
    } else if (
      isFoodCourt(props.tableId) &&
      (props.restaurant.onlineSettings?.foodCourtPaymentRequired ||
        props.restaurant.onlineSettings?.foodCourtPaymentOptional)
    ) {
      return true;
    }
    return false;
  };

  if (
    props?.placeOrderResponse?.reference &&
    props?.placeOrderResponse?.reference.length > 0
  ) {
    if (
      props?.placeOrderResponse?.payUrl &&
      props?.placeOrderResponse?.payUrl.trim().length > 0
    ) {
      if (props?.restaurant.onlineSettings?.isTillPaymentEnabled) {
        return true;
      } else {
        return false;
      }
    } else if (
      props?.placeOrderResponse?.stripeAccount &&
      props?.placeOrderResponse?.stripeAccount.length > 0 &&
      props?.selected === PaymentMethodType.BANK_CARD
    ) {
      return true;
    } else if (
      !checkRenderPaymentMethod() ||
      props?.selected === PaymentMethodType.CASH
    ) {
      if (isFoodCourt(props.tableId)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkOptionSizeDefault = (sizes: ISizeSelect[]) => {
  const sizeDefault = sizes.find((size: ISizeSelect) => {
    return size?.default;
  });
  return sizeDefault || null;
};



