export type TTableId =
  | "takeaway"
  | "pickup-delivery"
  | "pickup"
  | "delivery"
  | "foodcourt"
  | "";

export interface IItemCart {
  dish: IDishCart;
  isTakeaway: boolean;
  options: IOptionCart[];
  id: string;
  specialInstructions: string;
  quantity: number;
  isRequiredSelected: boolean;
  subTotal: number;
  size: any;
}

export interface IDishCart {
  groups: IGroupCart[];
  sizes: any;
  hidden_until?: any;
  altName: string;
  thumbnailImageUrl?: string;
  isTakeawayDisabled?: boolean;
  description: string;
  price: number;
  menuId: string;
  name: string;
  imageUrl: string;
  active_end?: any;
  active_begin?: any;
  ingredients: string;
  active: boolean;
  categoryId: string;
  sort: number;
  id: string;
}

export interface IOptionCart {
  id: string;
  menuId?: string;
  price: number;
  name: string;
  groupId: string;
  sort: number;
  default: boolean;
  quantity: number;
  shortName: string;
}

export interface IGroupCart {
  updated: IDateCart;
  createdAt: IDateCart;
  options: IOptionCart[];
  id: string;
  menuId: string;
  required: boolean;
  force_min: number;
  force_max: number;
  name: string;
  allow_quantity: boolean;
}

export interface IDateCart {
  _seconds: number;
  _nanoseconds: number;
}

export enum EFieldName {
  NAME = "Name",
  NUMBER_OF_GUEST = "Number of guest",
  PHONE_NUMBER = "Phone number",
  CODE = "Code",
  POST_CODE = "Post code",
  ADDRESS = "Address",
  TIME = 'Time',
  DATE = 'Date'
}

export enum PaymentMethodType {
  WECHAT = "wechat",
  ALI_PAY = "alipay",
  BANK_CARD = "card",
  CASH = "cash",
  PAY_PAL = "paypal",
}

export interface IRestaurant {
  logo: string;
  name: string;
  altName: string;
  address: string;
  phone: string;
  openHours: IOpenHours;
  serviceBell: boolean;
  onlineSettings: IOnlineSettings;
  serviceTypes: string[];
  smsVerify: boolean;
  takeawayPayment: boolean;
  themeColor: string;
  urlIdentifier: string;
  holidaySurcharge: IHolidaySurcharge;
  holidaySurchargeSettings: IHolidaySurchargeSetting;
  isBigMenuImageUsed: boolean;
  orderAheadDisabled: boolean;
  timezone: string;
  isDineMobileOptional: boolean;
}

export interface IOpenHours {
  closeDaysItems: string[];
  openHoursItems: IOpenHoursItem[];
}

export enum EPickupDelivery {
  PICKUP = "pickup",
  DELIVERY = "delivery",
}

export interface IHolidaySurcharge {
  amount: number | string;
  isEnabled: boolean;
  isPercentage: boolean;
}

export interface IHolidaySurchargeSetting extends IHolidaySurcharge {
  publicHolidayDays: string[];
  weekdays: string[];
}

export enum ELANGUAGE {
  ENGLISH = "English",
  CHINESE = "Chinese",
}

export enum EAdsType {
  banner = "banner",
  carasel = "carasel",
  grid = "grid",
  horizontalList = "horizontalList",
  verticalList = "verticalList",
}

export interface IAds {
  name: string;
  resources: IAdsResources[];
  sort: number;
  viewType: EAdsType;
}

export interface IAdsResources {
  type: string;
  url: string;
  externalUrl: string;
  language: ELANGUAGE;
}

export interface IMockDataFilterVisibility {
  cookingPriority: number;
  active_begin: number | null;
  active_end: number | null;
  activeWeekDays: any;
  active: boolean;
  hideForWeb: boolean;
  description: string;
  menuId: string;
  updatedAt: IDate;
  hidden_until: boolean | undefined;
  id: string;
  name: string;
  createdAt: IDate;
  sort: 6;
  altName: string | null;
  items: any[];
}

export interface IDate {
  _seconds: number;
  _nanoseconds: number;
}

export interface IItems {
  active: boolean;
  active_begin: boolean | null;
  active_end: boolean | null;
  altName: string;
  categoryId: string;
  cookingCategory: string;
  description: string;
  displayNumber: string;
  groups: any[];
  gstFree: boolean;
  hidden_until: boolean | null;
  id: string;
  image: string;
  ingredients: string;
  isOpenTableItem: boolean;
  isOpenTableSharedItem: boolean;
  menuId: string;
  name: string;
  oldId: string;
  price: number;
  productId: string;
  shortName: string;
  size: any[];
  sort: number;
  tag: string[];
}

export interface IOnlineSettings {
  allergyNotice?: string;
  dineinTakeawayEnabled?: boolean;
  foodCourtPaymentOptional?: boolean;
  foodCourtPaymentRequired?: boolean;
  foodCourtTakeawayEnabled?: boolean;
  hideSpecialRequirement?: boolean;
  isDineInGuestNumberRequired?: boolean;
  isDineInMobilePhoneRequired?: boolean;
  isPaypalEnabled?: boolean;
  isTillPaymentEnabled?: boolean;
  postpaidAutoAcceptAmountThreshold?: number;
  specialTerms?: string;
}

export interface IOpenHoursItem {
  id: string;
  openEnd: string;
  openStart: string;
  weekdays: string[];
}

export interface IProductItem {
  active: boolean;
  altName: string;
  categoryId: string;
  description: string;
  displayNumber: string;
  groups: any[];
  gstFree: boolean;
  id: string;
  image: string;
  isTakeawayDisabled?: boolean;
  thumbImage: string;
  imageUrl: string;
  ingredients: string;
  menuId: string;
  name: string;
  oldId: string;
  price: number;
  sizes: ISizeSelect[];
  sort: number;
  tags: string[];
  currency: string;
  menuItemId: string;
  shortName?: string;
}

export interface ISizeSelect {
  price: number;
  default: boolean;
  groups: [];
  id: string;
  menu_item_id: number;
  name: string;
}