import { IProductItem, IRestaurant } from 'features/home/types/homeTypes';
import { ISizeSelect } from '../components/orderGroupOptions/OrderGroupOptions';
import { IOrderOptionsData } from 'features/step-menu/pages/OrderDetail/types/OrderTypes';

export interface OrderOptionProps {
  productData: IProductItem;
  restaurant: IRestaurant;
  amountSurchargeFee: number | string;
  restaurantMessage: string;
  loading: boolean;
  handleSubmitAddToCart: () => void;
  quantity: number;
  orderData: IOrderData;
  isSwitchTakeaway: boolean;
  note: string;
  sizeSelected: ISizeSelect | null;
  setValue: (value, key: EKeySetValue) => void;
}

export interface IOrderGroupContainerProps {
  productData: IProductItem;
  loading: boolean;
  quantity: number;
  handleSubmitAddToCart: () => void;
  orderData: IOrderData;
  isSwitchTakeaway: boolean;
  note: string;
  sizeSelected: ISizeSelect | null;
  setValue: (value, key: EKeySetValue) => void;
}

export interface IOrderHeaderProps {
  disabled: boolean;
  handleSubmitAddToCart: () => void;
}

export interface IOrderData {
  groups: IOrderGroupsData[];
}

export interface IOrderGroupsData {
  id: string;
  options: IOrderOptionsData[];
}

export interface GroupOptionProps {
  productData: IProductItem;
  groupIndex: number;
  groupItem: any;
  orderData: any;
  setValue: (value, key: EKeySetValue) => void;
}

export interface IItemDetailContainerProps {
  productData: IProductItem;
  productDataNoEmpty: any[] | undefined;
}

export enum EKeySetValue {
  SET_DISABLED = 'setDisabled',
  SET_QUANTITY = 'setQuantity',
  SET_ORDER_DATA = 'setOrderData',
  SET_NOTE = 'setNote',
  SET_IS_SWITCH_TAKEAWAY = 'setIsSwitchTakeaway',
  SET_SIZE_SELECTED = 'setSizeSelected',
}

export interface IOptionStep {
  default: boolean;
  groupId: string;
  id: string;
  image: string;
  menuId: string;
  menuItemId: string;
  name: string;
  price: number;
  quantity: number;
  selected: boolean;
  shortName: string;
  sort: number;
}
