import API from 'api/api';
import { IInfo } from 'features/group/store/groupSlice';
import { createStoreCustom } from 'features/home/store/createStore';
import { TTableId } from 'features/home/types/homeTypes';
import i18n from 'i18n';
import moment from 'moment';
import { toast } from 'react-toastify';
import { renderItemsCart, renderMergeItemCart } from 'submodules/OrderCore';
import { EFetchingStatus } from 'types/globalType';
import { EEventAction, EEventCategory, appConstants } from 'utils/constants';
import { IHandleErrorProps, handleError, shortUUID } from 'utils/restaurantFeatures';
import {
  ICart,
  IOrderCart,
  IOrderHistory,
  IPlaceOrder,
  IPlaceOrderResponseAdyen,
  ISendCodeProps,
} from '../types/CartTypes';
import produce from 'immer';
import { AxiosResponse } from 'axios';

export interface IInfoCartGroup {
  groupId: string;
  restaurantId: string;
  tableId: string;
  restaurantName: string;
}
export interface CartItem {
  lastUpdated: Date;
  info: IInfoCartGroup;
  order: IOrderCart;
  restaurant: {
    restaurantId: string;
    tableId: string;
  };
  user: {
    name: string;
    email: string;
    phoneNumber: string;
    address: string;
    postcode: string;
    foodAllergies: string;
    geolocation: any;
    guestNum: string | number;
  };
  menuId: string;
  browser: string;
  mobileModel: string;
  verifyCode: string;
  sid: string;
  recaptcha: string;
}

export interface IRestaurantGroup {
  address: string;
  altName: string;
  isOnline?: boolean;
  logo?: string;
  name: string;
  openHours?: {
    closeDaysItems?: any[];
    openHoursItems?: any[];
  };
  restaurantId: string;
  restrauntDescription: string;
}

interface GroupCartState {
  cart: CartItem[];
  restaurant: IRestaurantGroup;
  tableId: string;
  status: EFetchingStatus;
  error?: string;
  placeOrderResponse: IPlaceOrder;
  reference: string;
  isDuplicate: boolean;
  placeOrderResponseAdyen: IPlaceOrderResponseAdyen;
  urlHome: string;
  orderHistory: IOrderHistory;
}

const initialState: GroupCartState = {
  cart: [],
  restaurant: {
    address: '',
    altName: '',
    isOnline: false,
    logo: '',
    name: '',
    openHours: {
      closeDaysItems: [],
      openHoursItems: [],
    },
    restaurantId: '',
    restrauntDescription: '',
  },
  tableId: '',
  status: EFetchingStatus.LOADING,
  placeOrderResponse: {
    isTillPayment: false,
    payUrl: '',
    reference: '',
    sessionId: '',
    stripeAccount: '',
  },
  reference: '',
  isDuplicate: false,
  placeOrderResponseAdyen: {
    clientKey: '',
    environment: '',
    isAdyenPayment: false,
    locale: '',
    dateStr: '',
    yearStr: '',
    countryCode: '',
    paymentResult: {
      amount: {
        value: 0,
        currency: '',
      },
      channel: '',
      countryCode: '',
      expiresAt: '',
      id: '',
      merchantAccount: '',
      reference: '',
      returnUrl: '',
      sessionData: '',
      shopperLocale: '',
      splits: [],
    },
    reference: '',
  },
  urlHome: '',
  orderHistory: {
    items: [],
  },
};

export interface IPlaceOrderProps {
  payload: ICart;
  infoRestaurant: IInfo;
  keyStorage: string;
  tableId: TTableId;
  orderNumber: string | undefined;
  eventTracker: (category: EEventCategory, action: EEventAction, message: string) => Promise<any>;
}

export interface IGroupCartStore {
  cartGroupState: GroupCartState;
  placeOrder: (mutated: AxiosResponse<any> | unknown) => void;
  sendCode: ({
    mobilePhone,
    recaptcha,
    restaurantId,
    tableId,
    eventTracker,
  }: ISendCodeProps) => void;
  addToGroupCart: (action) => void;
  selectRestaurant: (action) => void;
  increaseItemGroup: (action) => void;
  switchTakeawayItemGroup: (action) => void;
  removeItemGroup: (action) => void;
  clearAllItemGroup: () => void;
  updateCart: (action) => void;
  removePlaceOrderGroupResponse: () => void;
  updateCartGroup: (action) => void;
  updateOrderInCartGroup: (action) => void;
  updateRestaurantInCart: (action) => void;
  updateOrderInCartOrder: (action) => void;
  removePlaceOrderResponseAdyen: () => void;
  setOrderHistory: (order, reference, tableId) => void;
  setStatus: (status: EFetchingStatus) => void;
  handleErrorMutation: (
    err,
    { payload, infoRestaurant, keyStorage, tableId, orderNumber, eventTracker }: IPlaceOrderProps,
  ) => void;
}

export const useGroupCartStore = createStoreCustom<IGroupCartStore>(
  initialState,
  (get, set) => {
    return {
      placeOrder: async (mutated: AxiosResponse<any>) => {
        try {
          const data = mutated?.data;
          const isPaymentAdyen = data?.isAdyenPayment;
          set(
            produce((state: IGroupCartStore) => {
              state.cartGroupState.status = EFetchingStatus.SUCCEEDED;
            }),
          );
          if (isPaymentAdyen) {
            set(
              produce((state: IGroupCartStore) => {
                state.cartGroupState.placeOrderResponseAdyen = data;
              }),
            );
            return;
          }
          if (data?.reference) {
            set(
              produce((state: IGroupCartStore) => {
                state.cartGroupState.reference = data.reference;
                state.cartGroupState.placeOrderResponse = data;
              }),
            );
          }
        } catch (error: any) {}
      },
      sendCode: async ({
        mobilePhone,
        recaptcha,
        restaurantId,
        tableId,
        eventTracker,
      }: ISendCodeProps) => {
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.status = EFetchingStatus.LOADING;
          }),
        );
        try {
          const res = await API.post('/sendCode', {
            mobilePhone,
            recaptcha,
            restaurantId,
          });
          const data = res.data;
          if (res && res.data && res.data.sid) {
            const tempCart = get().cartGroupState.cart.map((item: any) => {
              if (item.info.restaurantId === data?.menuId) {
                return {
                  ...item,
                  sid: data?.sid,
                };
              }
              return item;
            });
            set(
              produce((state: IGroupCartStore) => {
                state.cartGroupState.status = EFetchingStatus.SUCCEEDED;
                state.cartGroupState.cart = tempCart;
              }),
            );
          }
        } catch (error: any) {
          set(
            produce((state: IGroupCartStore) => {
              state.cartGroupState.status = EFetchingStatus.FAILED;
              state.cartGroupState.error = error?.message;
            }),
          );
          const errorData: IHandleErrorProps = {
            logErrorData: {
              endpoint: 'sendCode',
              menuId: restaurantId,
              orderNumber: '',
              tableId,
              error,
              fileName: 'cartSliceGroup',
              functionName: 'sendCodeAPI',
            },
            eventTracker,
          };
          handleError(errorData);
          toast.error(i18n.t('Something went wrong! Please try again.'));
        }
      },
      addToGroupCart: (action) => {
        const tempStateCart = [...get().cartGroupState.cart];
        const isAvailable = tempStateCart.filter((itemCart: CartItem) => {
          const isMatch =
            itemCart.info.groupId === action.info.groupId &&
            itemCart.info.restaurantId === action.info.restaurantId &&
            itemCart.info.tableId === action.info.tableId;

          return isMatch;
        });
        const newCart = tempStateCart.map((itemCart: CartItem) => {
          if (
            itemCart.info.groupId === action.info.groupId &&
            itemCart.info.restaurantId === action.info.restaurantId &&
            itemCart.info.tableId === action.info.tableId
          ) {
            return {
              ...itemCart,
              order: {
                ...itemCart?.order,
                items: renderItemsCart(itemCart?.order.items, action.item),
              },
            };
          } else {
            return itemCart;
          }
        });
        if (isAvailable.length === 0) {
          set(
            produce((state: IGroupCartStore) => {
              state.cartGroupState.cart = [
                ...get().cartGroupState.cart,
                {
                  lastUpdated: new Date(),
                  info: action.info,
                  order: {
                    items: [action.item],
                    orderNumber: shortUUID(),
                    pickupTimeType: 'now',
                    pickupTime: '',
                    selectedDate: '',
                    total: 0,
                    paymentType: null,
                    isPaymentRequired: false,
                    type: '',
                    realTableId: '',
                    notes: '',
                    orderTime: '',
                    restaurant: '',
                  },
                  restaurant: {
                    restaurantId: action.info.restaurantId,
                    tableId: action.info.tableId,
                  },
                  user: {
                    name: '',
                    email: '',
                    phoneNumber: '',
                    address: '',
                    postcode: '',
                    foodAllergies: '',
                    geolocation: {},
                    guestNum: '',
                  },
                  menuId: action.info.restaurantId,
                  browser: 'Chrome',
                  mobileModel: 'none',
                  verifyCode: '',
                  sid: '',
                  recaptcha: '',
                },
              ];
            }),
          );
        } else {
          set(
            produce((state: IGroupCartStore) => {
              state.cartGroupState.cart = newCart;
            }),
          );
        }
        // toast.success(i18n.t(getMessageAddToCart(action.item.quantity)), {
        //   autoClose: TOAST_TIME_MESSAGE_ADD_TO_CART,
        // });
      },
      selectRestaurant: (action) => {
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.restaurant = action.restaurant;
            state.cartGroupState.tableId = action.tableId;
          }),
        );
      },
      increaseItemGroup: (action) => {
        const index = action.index;
        const newCart = [...get().cartGroupState.cart]?.map((item) => {
          if (
            item.info.tableId === action.info.tableId &&
            item.info.restaurantId === action.info.restaurantId
          ) {
            const newItem = [...item?.order.items];
            const temp = { ...newItem[index] };
            temp.quantity++;
            newItem.splice(index, 1, temp);
            return {
              ...item,
              order: {
                ...item.order,
                items: newItem,
              },
            };
          } else {
            return item;
          }
        });
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.cart = [...newCart];
          }),
        );
      },
      switchTakeawayItemGroup: (action) => {
        const index = action.index;
        const newCart = [...get().cartGroupState.cart].map((item: any) => {
          if (
            item.info.restaurantId === action.cartInfo.restaurantId &&
            item.info.tableId === action.cartInfo.tableId
          ) {
            const items = [...item?.order.items];
            const temp = { ...items[index] };
            temp.isTakeaway = !temp.isTakeaway;
            const index2 = renderMergeItemCart(items, temp, index);
            if (index2 !== false) {
              const item2 = { ...items[index2] };
              temp.quantity += item2.quantity;
              items.splice(index2, 1);
            }
            return {
              ...item,
              order: {
                ...item.order,
                items: items,
              },
            };
          } else {
            return item;
          }
        });
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.cart = [...newCart];
          }),
        );
      },
      removeItemGroup: (action) => {
        const index = action.index;
        const newCart = [...get().cartGroupState.cart]?.map((item) => {
          if (
            item.info.tableId === action.info.tableId &&
            item.info.restaurantId === action.info.restaurantId
          ) {
            let newItem = [...item?.order.items];
            const temp = { ...newItem[index] };
            if (temp.quantity > 1) {
              temp.quantity--;
              newItem.splice(index, 1, temp);
            } else {
              newItem.splice(index, 1);
            }
            return {
              ...item,
              order: {
                ...item.order,
                items: newItem,
              },
            };
          } else {
            return item;
          }
        });
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.cart = [...newCart];
          }),
        );
      },
      clearAllItemGroup: () => {
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.cart = [];
          }),
        );
      },
      updateCart: (action) => {
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.cart = action;
          }),
        );
      },
      removePlaceOrderGroupResponse: () => {
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.isDuplicate = false;
            state.cartGroupState.placeOrderResponse = {
              isTillPayment: false,
              payUrl: '',
              reference: '',
              sessionId: '',
              stripeAccount: '',
            };
          }),
        );
      },
      updateCartGroup: (action) => {
        const tempCart = [...get().cartGroupState.cart];
        const newCart = tempCart.map((item: any) => {
          if (
            item.info.restaurantId === action.info.menuId &&
            item.info.tableId === action.info.tableId
          ) {
            return {
              ...item,
              order: {
                items: [],
              },
            };
          } else {
            return item;
          }
        });
        const newCart2 = newCart.filter((item) => {
          return item?.order.items.length !== 0;
        });
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.cart = newCart2;
          }),
        );
      },
      updateOrderInCartGroup: (action) => {
        const temp = [...get().cartGroupState.cart];
        const newCart = temp.map((item: any) => {
          if (item.info.restaurantId === action.info.menuId) {
            return {
              ...item,
              order: action.order,
              lastUpdated: new Date(),
            };
          } else {
            return item;
          }
        });
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.cart = newCart;
          }),
        );
      },
      updateRestaurantInCart: (action) => {
        const temp = get().cartGroupState.cart.map((item: any) => {
          if (item.info.restaurantId === action.menuId) {
            return {
              ...item,
              restaurant: action,
              lastUpdated: new Date(),
            };
          } else {
            return item;
          }
        });
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.cart = temp;
          }),
        );
      },
      updateOrderInCartOrder: (action) => {
        const tempOrder = [...get().cartGroupState?.cart].filter((item: any) => {
          return item.info.restaurantId !== action.info.menuId;
        });
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.cart = tempOrder;
            state.cartGroupState.isDuplicate = false;
          }),
        );
      },
      removePlaceOrderResponseAdyen: () => {
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.placeOrderResponseAdyen = {
              clientKey: '',
              environment: '',
              isAdyenPayment: false,
              locale: '',
              dateStr: '',
              yearStr: '',
              countryCode: '',
              paymentResult: {
                amount: {
                  value: 0,
                  currency: '',
                },
                channel: '',
                countryCode: '',
                expiresAt: '',
                id: '',
                merchantAccount: '',
                reference: '',
                returnUrl: '',
                sessionData: '',
                shopperLocale: '',
                splits: [],
              },
              reference: '',
            };
          }),
        );
      },
      setOrderHistory: (order, reference, tableId) => {
        const state = get().cartGroupState;
        let newOrderHistory = state.orderHistory;
        const selectedDate = newOrderHistory?.items[0]?.order.selectedDate;
        const today = moment().utc().format(appConstants.dateFormat);

        if (today === selectedDate && newOrderHistory) {
          newOrderHistory = {
            items: [{ order, reference, tableId }, ...newOrderHistory.items],
          };
        } else {
          newOrderHistory = {
            items: [{ order, reference, tableId }],
          };
        }
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.orderHistory = newOrderHistory;
            state.cartGroupState.reference = reference;
          }),
        );
      },
      setStatus: (status: EFetchingStatus) => {
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.status = status;
          }),
        );
      },
      handleErrorMutation: (
        error,
        { payload, infoRestaurant, tableId, orderNumber, eventTracker }: IPlaceOrderProps,
      ) => {
        const data = error?.response?.data;
        const errorData: IHandleErrorProps = {
          logErrorData: {
            endpoint: 'placeOrder',
            menuId: infoRestaurant?.menuId,
            orderNumber,
            tableId,
            error,
            fileName: 'cartSliceGroup',
            functionName: 'placeOrderAPI',
            payload: JSON.stringify(payload || ''),
          },
          eventTracker,
        };
        handleError(errorData);
        if (data?.duplicate) {
          set(
            produce((state: IGroupCartStore) => {
              state.cartGroupState.isDuplicate = true;
            }),
          );
          toast.success(i18n.t(data?.message));
          return;
        }
        set(
          produce((state: IGroupCartStore) => {
            state.cartGroupState.status = EFetchingStatus.FAILED;
            state.cartGroupState.error = error?.message;
          }),
        );
        return error.response;
      },
    };
  },
  'cartGroup',
  'cartGroupState',
);
