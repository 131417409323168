import googleAnalytics from '@analytics/google-analytics';
import Analytics from 'analytics';

const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;
const APP_NAME = 'ordering';
const version = '0.1.0';

export const analytics = Analytics({
  app: APP_NAME,
  version: version,
  plugins: [
    googleAnalytics({
      measurementIds: [MEASUREMENT_ID],
    }),
  ],
});
declare global {
  interface Window {
    Analytics: any;
  }
}

window.Analytics = analytics;
