import {
  MESSAGE_ADD_TO_CART_ITEMS_SUCCESS,
  MESSAGE_ADD_TO_CART_ITEM_SUCCESS,
} from "../constants";
import { IItemCart } from "../types";

export const renderItemsCart = (items: IItemCart[], item: IItemCart) => {
  const dishes = JSON.parse(JSON.stringify(items));
  let index = dishes.findIndex((dishItem: any) => {
    return (
      (item.options.length === 0 &&
        item.dish.id === dishItem.dish.id &&
        dishItem.options.length === 0 &&
        item.specialInstructions === dishItem.specialInstructions &&
        item.isTakeaway === dishItem.isTakeaway &&
        item?.size?.id === dishItem?.size?.id) ||
      (item.dish.id === dishItem.dish.id &&
        item.specialInstructions === dishItem.specialInstructions &&
        JSON.stringify(item.options) === JSON.stringify(dishItem.options) &&
        item.isTakeaway === dishItem.isTakeaway &&
        item?.size?.id === dishItem?.size?.id)
    );
  });
  if (index >= 0) {
    dishes[index].quantity += item.quantity;
    return dishes;
  }
  return [...dishes, item];
};

export const renderMergeItemCart = (
  items: IItemCart[],
  item: IItemCart,
  indexItem: number
) => {
  const dishes = JSON.parse(JSON.stringify(items));
  const indexes: any = [];
  dishes.forEach((dishItem: any, index: any) => {
    if (
      (item.options.length === 0 &&
        item.dish.id === dishItem.dish.id &&
        dishItem.options.length === 0 &&
        item.specialInstructions === dishItem.specialInstructions &&
        item.isTakeaway === dishItem.isTakeaway) ||
      (item.dish.id === dishItem.dish.id &&
        item.specialInstructions === dishItem.specialInstructions &&
        JSON.stringify(item.options) === JSON.stringify(dishItem.options) &&
        item.isTakeaway === dishItem.isTakeaway)
    ) {
      indexes.push(index);
    }
  });

  if (indexes.length >= 2) {
    const index2 = indexes.filter((x: any) => x !== indexItem);
    return index2[0];
  }
  return false;
};

export const getMessageAddToCart = (quantity: number) => {
  const message =
    quantity === 1
      ? MESSAGE_ADD_TO_CART_ITEM_SUCCESS
      : MESSAGE_ADD_TO_CART_ITEMS_SUCCESS;
  return message;
};

export const checkShowOption = (optionItem: any, inactiveMenuList: any) => {
  return (
    (!optionItem.menuItemId ||
      (optionItem.menuItemId &&
        !inactiveMenuList?.includes(optionItem.menuItemId))) &&
    !optionItem.hideForWeb
  );
};
